import { Component, OnInit } from "@angular/core";
import { HttpAirtimeService } from "../../../http-airtime.service";
import { HttpKioskService } from "../../../http-kiosk.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { catchError } from "rxjs/operators";
import { BundleOfferRangeDto } from "../../../dto";

@Component({
	selector: 'app-range-config',
	templateUrl: './range-config-list.component.html',
	styleUrls: ['./range-config-list.component.scss']
})
export class RangeListComponent implements OnInit {

	rangeConfig: BundleOfferRangeDto[];

	constructor(
		private httpService: HttpAirtimeService,
		private httpKioskService: HttpKioskService,
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,
		private router: Router,
		private modalService: NgbModal,
		private activatedRoute: ActivatedRoute) {}

	ngOnInit(): void {
		this.spinner.show();
		this.httpService.getBundleConfig()
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching bundle config', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(resp => {
			this.rangeConfig = resp;
			this.spinner.hide();
		});
	}
}