import { Component, Input } from "@angular/core";
import { AirtimeDataDto, AirtimeDto } from "../../dto";
import { faCancel, faCheck, faIdCard, faMoneyBills, faSearch, faSimCard } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from "@angular/router";
import { UtilService } from "../../util.service";
import { TpRequestResponseModalComponent } from "./tp-request-response-modal/tp-request-response-modal.component";
import { AppToastService } from "src/app/components/toasts/app-toast.service";

@Component({
	selector: 'app-airtime-table-data',
	templateUrl: './airtime-table-data.component.html',
	styleUrls: ['./airtime-table-data.component.scss']
})
export class AirtimeTableDataComponent {

	@Input() payload: AirtimeDataDto[];

	util = UtilService;

	constructor(
		private modalService: NgbModal,
		private activatedRoute: ActivatedRoute,
		private toastService: AppToastService,) {}

	view = faSearch;
	idCard = faIdCard;
	momo = faMoneyBills;
	simCard=faSimCard;

	ok=faCheck;
	notOk=faCancel;

	viewTp(data: AirtimeDataDto) {
		if (!data.tpRequest) {
			this.toastService.showInfo({ content: 'Third Party request information not available' });
			return;
		}
		this.showTpRequestResponseModal(data);
	}
	private showTpRequestResponseModal(data: AirtimeDataDto) {
		const modalRef = this.modalService.open(TpRequestResponseModalComponent, { windowClass: "modal-tp-request-response" , size:  'lg'});
		modalRef.componentInstance.tpRequest = data.tpRequest;
		modalRef.componentInstance.tpResponse = data.tpResponse;
		modalRef.componentInstance.tpName = data.tpName;
		modalRef.componentInstance.tpUrl = data.tpUrl;
		modalRef.componentInstance.tpHeaders = data.tpHeaders;
		modalRef.componentInstance.tpRequestDate = data.tpRequestDate;
		modalRef.componentInstance.tpResponseDate = data.tpResponseDate;
		modalRef.componentInstance.tpTiming = data.tpTiming;
	}
	getIcon(val: boolean) {
		return val === true ? this.ok : this.notOk;
	}
	getIconClass(val: boolean) {
		return val === true ? 'text-success' : 'text-danger';
	}
}