import { Component, Input } from "@angular/core";
import { CustomerDto, CustomerSimDto } from "../../../dto";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-tp-request-response-modal',
	templateUrl: './tp-request-response-modal.component.html',
	styleUrls: ['./tp-request-response-modal.component.scss']
})
export class TpRequestResponseModalComponent {
	@Input() tpRequest: string;
	@Input() tpResponse: string;
	@Input() tpName: string;
	@Input() tpUrl: string;
	@Input() tpHeaders: string;
	@Input() tpRequestDate: Date;
	@Input() tpResponseDate: Date;
	@Input() tpTiming: number;

	constructor(
		public modal: NgbActiveModal,
	) {}

	close() {
		this.modal.close(false);
	}
}