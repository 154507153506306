<div class="row" *ngIf="payload">
	<div class="col col-md-10 col-lg-6 mx-auto">
		<div class="card m-3 shadow-lg bg-white rounded">
			<div class="card-header">
				<div class="row">
					<div class="col pt-1"><fa-icon [icon]="person" class="text-secondary"></fa-icon>&nbsp;Customer</div>
					<div class="col text-end">
						<button class="float-end btn btn-outline-secondary" (click)="goBack()"><fa-icon [icon]="back" size="sm" ></fa-icon></button>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col">
						<div class="card rounded">
							<div class="card-header"><fa-icon [icon]="tick" class="text-secondary" size="lg"></fa-icon>&nbsp;Checklist</div>
							<div class="card-body table-responsive checklist text-center">
								<div class="row">
									<div class="col">
										<fa-icon [icon]="details" class="text-secondary" size="lg"></fa-icon><br/>
										Details<br/>
										<ng-container [ngTemplateOutlet]="checklist" [ngTemplateOutletContext]="{value:true}"></ng-container>
									</div>
									<div class="col">
										<fa-icon [icon]="portraitIcon" class="text-secondary" size="lg"></fa-icon><br/>
										Portrait<br/>
										<ng-container [ngTemplateOutlet]="checklist" [ngTemplateOutletContext]="{value:getImage(ImageTypes.Portrait) !== undefined}"></ng-container>
									</div>
									<div class="col">
										<fa-icon [icon]="idCard" class="text-secondary" size="lg"></fa-icon><br/>
										Documents<br/>
										<ng-container [ngTemplateOutlet]="checklist" [ngTemplateOutletContext]="{value:getImage(ImageTypes.Document) !== undefined}"></ng-container>
									</div>
									<div class="col" *ngIf="env.displayMomoAccount">
										<fa-icon [icon]="momo" class="text-secondary" size="lg"></fa-icon><br/>MoMo 
										Account<br/>
										<ng-container [ngTemplateOutlet]="checklist" [ngTemplateOutletContext]="{value:payload.customer.momoAccount !== null}"></ng-container>
									</div>
									<div class="col">
										<fa-icon [icon]="biometrics" class="text-secondary" size="lg"></fa-icon><br/>
										Biometrics<br/>
										<ng-container [ngTemplateOutlet]="checklist" [ngTemplateOutletContext]="{value:payload.prints.length > 0}"></ng-container>
									</div>
									<div class="col">
										<fa-icon [icon]="signature" class="text-secondary" size="lg"></fa-icon><br/>
										Signature<br/>
										<ng-container [ngTemplateOutlet]="checklist" [ngTemplateOutletContext]="{value:getImage(ImageTypes.Signature) !== undefined}"></ng-container>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col">
						<div class="card rounded">
							<div class="card-header"><fa-icon [icon]="details" class="text-secondary" size="lg"></fa-icon>&nbsp;Details</div>
							<div class="card-body table-responsive">
								<table class="table table-borderless">
									<tbody>
										<tr>
											<th>Captured</th>
											<td>{{payload.customer.createdAt  | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
										</tr>
										<tr>
											<th>Name</th>
											<td>{{payload.customer.name}}</td>
										</tr>
										<tr>
											<th>Surname</th>
											<td>{{payload.customer.surname}}</td>
										</tr>
										<tr>
											<th>Birthdate</th>
											<td>{{payload.customer.birthDate | date: 'yyyy-MM-dd'}}</td>
										</tr>
										<tr>
											<th>Gender</th>
											<td>{{payload.customer.gender}}</td>
										</tr>
										<tr>
											<th>Nationality</th>
											<td>{{payload.customer.nationality}}</td>
										</tr>
										<tr>
											<th>Payment Type</th>
											<td>{{payload.customer.paymentType}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="card rounded">
							<div class="card-header"><fa-icon [icon]="portraitIcon" class="text-secondary" size="lg"></fa-icon>&nbsp;Portrait</div>
							<div class="card-body text-center scroll-col">
								<div *ngFor="let img of getImage(ImageTypes.Portrait)">
									<app-customer-image [image]="img"></app-customer-image>
									<!-- <img *ngIf="img.image" [src]="img.image" class="clickable" height="250px" (click)="viewOriginal(img)"> -->
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col">
						<div class="card rounded">
							<div class="card-header"><fa-icon [icon]="idCard" class="text-secondary" size="lg"></fa-icon>&nbsp;ID</div>
							<div class="card-body table-responsive">
								<table class="table table-borderless">
									<tbody>
										<tr>
											<th>Number</th>
											<td>{{payload.customer.idNumber}}</td>
										</tr>
										<tr>
											<th>Document Number</th>
											<td>{{payload.customer.documentNumber}}</td>
										</tr>
										<tr>
											<th>Personal Number</th>
											<td>{{payload.customer.personalNumber}}</td>
										</tr>
										<tr>
											<th>Type</th>
											<td>{{payload.customer.idType}}</td>
										</tr>
										<tr>
											<th>Issued</th>
											<td>{{payload.customer.idIssuedAt | date: 'yyyy-MM-dd'}}</td>
										</tr>
										<tr>
											<th>Expiry</th>
											<td>{{payload.customer.idExpiryAt | date: 'yyyy-MM-dd'}}</td>
										</tr>
										<tr>
											<th>Issue Place</th>
											<td>{{payload.customer.idIssuePlace}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="row p-0">
							<div class="col p-0">
								<div class="card rounded">
									<div class="card-header"><fa-icon [icon]="idFront" class="text-secondary" size="lg"></fa-icon>&nbsp;ID Documents</div>
									<div class="card-body text-center scroll-col">
										<div *ngFor="let img of getImage(ImageTypes.Document)" class="mb-2">
											<!-- <img *ngIf="img.image" [src]="img.image" class="clickable" height="250px" (click)="viewOriginal(img)"> -->
											<app-customer-image [image]="img"></app-customer-image>
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
				<div class="row mt-2">
					<div class="col">
						<div class="card rounded">
							<div class="card-header"><fa-icon [icon]="simcard" class="text-secondary" size="lg"></fa-icon>&nbsp;SIM(s)</div>
							<div class="card-body table-responsive">
								<table class="table table-bordered">
									<thead>
										<tr><th>ICCID</th></tr>
									</thead>
									<tbody>
										<tr *ngFor="let sim of payload.sims">
											<td>{{sim.iccid}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="col">
						<div *ngIf="env.displayMomoAccount" class="card rounded">
							<div class="card-header"><fa-icon [icon]="momo" class="text-secondary" size="lg"></fa-icon>&nbsp;MoMo Account</div>
							<div *ngIf="!payload.customer.momoAccount" class="card-body">Customer does not have a MoMo account</div>
							<div *ngIf="payload.customer.momoAccount" class="card-body">
								<table class="table table-borderless">
									<tbody>
										<tr>
											<th>Account</th>
											<td>{{payload.customer.momoAccount}}</td>
										</tr>
										<tr>
											<th>Balance</th>
											<td>{{payload.customer.momoBalance}}</td>
										</tr>
										<tr>
											<th>Last Tranfer</th>
											<td>{{payload.customer.momoLastTransfer}}</td>
										</tr>
										<tr>
											<th>Last Recharge</th>
											<td>{{payload.customer.momoLastRecharge}}</td>
										</tr>
										<tr>
											<th>Last 3 Dialed</th>
											<td>{{payload.customer.momoLastThreeDialed}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-2" *ngIf="hasAdditionalImages()">
					<div class="col">
						<div class="card rounded">
							<div class="card-header"><fa-icon [icon]="additionalImages" class="text-secondary" size="lg"></fa-icon>&nbsp;Additional Images</div>
							<div class="card-body">
								<div class="row">
									<div *ngFor="let imgInfo of getAdditionalImages()" class="col text-center fingerprint text-secondary">
										{{getImageType(imgInfo.imageType)}}<br/>
										<app-customer-image [image]="imgInfo.imageData"></app-customer-image>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-2" *ngIf="payload.prints && payload.prints.length > 0">
					<div class="col">
						<div class="card rounded">
							<div class="card-header"><fa-icon [icon]="biometrics" class="text-secondary" size="lg"></fa-icon>&nbsp;Biometrics</div>
							<div class="card-body">
								<div class="row">
									<div *ngFor="let print of payload.prints" class="col text-center fingerprint text-secondary">
										{{getFingerprintType(print.type)}}<br/>
										<!-- <img [src]="print.jpeg" class="clickable" height="150px"> -->
										<app-customer-print [print]="print"></app-customer-print>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-2" *ngIf="getImage(ImageTypes.Signature)">
					<div class="col">
						<div class="card rounded">
							<div class="card-header"><fa-icon [icon]="signature" class="text-secondary" size="lg"></fa-icon>&nbsp;Signature</div>
							<div class="card-body text-center scroll-col">
								<div *ngFor="let img of getImage(ImageTypes.Signature)">
									<!-- <img *ngIf="img.image" [src]="img.image" class="clickable" height="250px" (click)="viewOriginal(img)"> -->
									<app-customer-image [image]="img"></app-customer-image>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #checklist let-value='value'>
	<fa-icon *ngIf="value === true" [icon]="tick" class="text-success fs-5"></fa-icon>
	<fa-icon *ngIf="value === false" [icon]="cross" class="text-danger fs-5"></fa-icon>
</ng-template>