import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { KioskUserDto, MaintenancePermissionConfigDto, MaintenancePermissionDto, MaintenancePermissionRoleDto, MaintenancePermissionsEnum } from "../../dto";
import { faCheck, faPerson, faHourglassStart, faUnlock, faBan, faEdit, faSearch, faLock, faPlusCircle, faCross, faTimes, faQuestionCircle, faArrowRotateRight, faCalendar, faArrowRotateForward, faArrowTurnUp, faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { forkJoin, Subscription } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpTechnicianService } from "../../http-technician.service";
import { TechnicianActionService } from "../../kiosk-technician.service";
import { main } from "@popperjs/core";

class UserPermissionGroup {
		user: KioskUserDto;
		group: CategoryGroup[];
}
class CategoryGroup {
	category: string;
	config: MaintenancePermissionConfigDto[];
}

@Component({
	selector: 'app-technician-maintenance-config',
	templateUrl: './technician-maintenance-config.component.html',
	styleUrls: ['./technician-maintenance-config.component.scss']
})
export class TechnicianMaintenanceConfigComponent implements OnInit, OnDestroy {

	maintenancePermissions: MaintenancePermissionDto[];
	maintenanceRoles: MaintenancePermissionRoleDto[];

	add = faPlusCircle;
	hasPerm = faCheck;
	noPerm = faTimes;
	edit = faEdit;
	tooltip = faQuestionCircle;
	switch = faArrowRightArrowLeft;
	date=faCalendar;

	userPerms: UserPermissionGroup[] = [];

	subscription = new Subscription();

	constructor(
		private httpService: HttpTechnicianService, 
		private technicianActionService: TechnicianActionService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,) {
			this.subscription = this.technicianActionService.onActionSuccess.subscribe (item => {
				switch (item.action) {
					case "Edit":
					case "Disable":
					case "Unblock":
					case "ReEnable":
						this.fetchAll();
						break;
				}
			});
	}

	ngOnInit(): void {
		this.fetchAll();
	}

	private fetchAll() {
		this.spinner.show();
		forkJoin([this.httpService.getAllMaintenancePermissions(), this.httpService.getAllMaintenancePermissionRoles()])
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching maintenance permissions', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(items => {
			this.maintenancePermissions = items[0];
			this.maintenanceRoles = items[1];
			console.log('Maintenance Permissions:', this.maintenancePermissions);
			this.groupCategories();
			this.spinner.hide();
		});
	}

	private groupCategories() {
		this.userPerms = [];
		this.maintenancePermissions.forEach(item => {

			var userPerm: UserPermissionGroup = {
				user: item.user,
				group: []
			};

			var currentCategory = undefined;
			item.config.forEach(config => {

				if (config.allowedUntil) {
					var date = new Date(config.allowedUntil);
					config.allowedUntilNgb =  {
						day: date.getDate(),
						month: date.getMonth() + 1,
						year: date.getFullYear()
					}
				}

				var categoryGroup: CategoryGroup = undefined;
				if (currentCategory !== config.category) {
					categoryGroup = {
						category: config.category,
						config: []
					}
					categoryGroup.config.push(config);
					userPerm.group.push(categoryGroup);
				} else {
					categoryGroup = userPerm.group.filter(item => item.category === config.category)[0];
					categoryGroup.config.push(config);
				}
				currentCategory = config.category;
			});

			item.role = this.maintenanceRoles.filter(role => role.role === item.role.role)[0];

			this.userPerms.push(userPerm);

		});
		console.log('Organised User Perms:', this.userPerms);
	}

	getUserPerm(userId: number): UserPermissionGroup {
		return this.userPerms.filter(item => item.user.id === userId)[0];
	}

	addTechnician() {
		this.router.navigate(['/kiosk','add-technician']);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	switchAllowedUntil(config: MaintenancePermissionConfigDto) {
		if (config.allowedUntil) {
			config.allowedUntil = undefined;
			config.allowedUntilNgb = undefined;
		} else {
			var date = new Date();
			config.allowedUntil = new Date();
			config.allowedUntil.setFullYear(date.getFullYear());
			config.allowedUntil.setMonth(date.getMonth());
			config.allowedUntil.setDate(date.getDate() + 1);

			config.allowedUntilNgb =  {
				day: config.allowedUntil.getDate(),
				month: config.allowedUntil.getMonth() + 1,
				year: config.allowedUntil.getFullYear()
			}
		}
	}

	save(permission: MaintenancePermissionDto) {
		var changedPerms = this.userPerms.filter(item => item.user.id === permission.user.id)[0];
		var forServer: MaintenancePermissionDto = {
			user: permission.user,
			config: []
		}
		changedPerms.group.forEach(group => {
			group.config.forEach(config => {
				if (config.allowedUntilNgb) {
					config.allowedUntil = new Date();
					config.allowedUntil.setFullYear(config.allowedUntilNgb.year);
					config.allowedUntil.setMonth(config.allowedUntilNgb.month -1);
					config.allowedUntil.setDate(config.allowedUntilNgb.day);
				}

				forServer.config.push(config);
				forServer.role = permission.role;
			})
		});
		console.log(forServer);

		this.spinner.show();
		this.httpService.saveMaintenancePermissions(forServer)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error saving maintenance permissions', error);
				this.toastService.showDanger({ content: 'Failed to save permissions: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(rsp => {
			this.spinner.hide();
			if (rsp.responseStatus) {
				this.toastService.showInfo({ content: 'Permissions saved successfully' });
				return;
			}
			this.toastService.showDanger({ content: 'Failed to save permissions: ' + rsp.responseStatusMessage });
		});
	}

	toggleAllow(config: MaintenancePermissionConfigDto) {
		config.allowed = !config.allowed;
	}

	onMaintenanceRoleChange(maintenance: MaintenancePermissionDto, $event) {
		var role: MaintenancePermissionRoleDto = $event;
		console.log('Current perms: ', maintenance.config);
		console.log('Updating to: ', role.permissions);
		maintenance.config = [];
		role.permissions.forEach(item => {
			var perm: MaintenancePermissionConfigDto = {
				id: item.id,
				category: item.category,
				permission: item.permission,
				allowed: true,
				allowedUntil: undefined,
				allowedUntilNgb: undefined,
				description: item.description,
			}
			maintenance.config.push(perm);
		});
		this.groupCategories();
	}
}