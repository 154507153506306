import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BundleOfferDto, BundleOfferIconDto, BundleOfferRangeDto } from "../../../dto";
import { faBan, faCancel, faCheck, faEdit, faIdCard, faMoneyBills, faSearch, faSimCard, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from "@angular/router";
import { UtilService } from "../../../util.service";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { Location } from "@angular/common";
import { AuthService } from "src/app/service/auth.service";
import { environment } from "src/environments/environment";
import { EditOfferModalComponent } from "./offer-edit-modal/offer-edit-modal.component";
import { HttpAirtimeService } from "../../../http-airtime.service";
import { catchError } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";

class Icon {
	title: string;
	id: number;
	icon?: any;
}

class Header {
	title: string;
	type: 'text' | 'icon'
}

@Component({
	selector: 'app-offer-table-data',
	templateUrl: './offer-table-data.component.html',
	styleUrls: ['./offer-table-data.component.scss']
})
export class OfferTableDataComponent implements OnInit {

	@Input() range: BundleOfferRangeDto;
	@Input() offerConfig: BundleOfferDto[];
	@Output() onActionSuccess: EventEmitter<any> = new EventEmitter(); 

	icons: Icon[] = [];

	util = UtilService;

	constructor(
		private authService: AuthService,
		private location: Location, 
		private modalService: NgbModal,
		private toastService: AppToastService,
		private httpService: HttpAirtimeService,
		private spinner: NgxSpinnerService) {}

	view = faSearch;
	disabled = faBan;
	unlock = faUnlock;
	edit = faEdit;

	ok=faCheck;
	notOk=faCancel;

	bundleIcon: BundleOfferIconDto;

	ngOnInit(): void {
		console.log("this.offerConfig",this.offerConfig);
		this.offerConfig.forEach(offer => {
			offer.icons.forEach(icon => {
				this.httpService.getBundleIcon(icon.id, icon.language.toString().toLowerCase())
				.subscribe(data => {
					this.createImageFromBlob(data, icon);
				});	
				
			});
		});
	}

	createImageFromBlob(image: Blob, bundleIcon: BundleOfferIconDto) {
		var reader = new FileReader();
		reader.addEventListener("load", () => {
			var icon: Icon = {
				icon: reader.result,
				id: bundleIcon.id,
				title: bundleIcon.language.toString()
			}
			this.icons.push(icon);
			
		}, false);
	 
		if (image) {
		   reader.readAsDataURL(image);
		}
	}

	getIcon(val: boolean) {
		return val === true ? this.ok : this.notOk;
	}
	getIconClass(val: boolean) {
		return val === true ? 'text-success' : 'text-danger';
	}

	get headers(): Header[] {
		var headers = this.offerConfig[0].extras.map(extra =>{
			var header: Header = {
				title: extra.name,
				type: 'text'
			}
			return header;
		});
		var iconTitles: Set<String> = new Set();
		this.icons.forEach(icon => {
			iconTitles.add(icon.title);
		});
		
		iconTitles.forEach(title => {
			var header: Header = {
				title: title+'',
				type: 'icon'
			}
			headers.push(header);
		});

		// console.log(headers);

		return headers;
	}

	getValue(header: Header, offer: BundleOfferDto) {
		var extra = offer.extras.filter(extra => extra.name === header.title)[0];
		return extra.value;
	}
	getBundleIcon(title: string, bundleOffer: BundleOfferDto): any {
		var offerIcon = bundleOffer.icons.filter(item => item.language.toString() === title)[0];
		return this.icons.filter(icon => icon.id === offerIcon.id).filter(icon => icon.title === offerIcon.language.toString())[0]?.icon;
	}

	getButtonVisible(action: string): boolean {
		const allowedAction = environment.allowedActions.filter(item => item.action === action)[0];
		if (!allowedAction) {
			return false;
		}
		
		return this.authService.hasAnyRole(allowedAction.role);
	}
	editOffer(offer: BundleOfferDto) {
		const modalRef = this.modalService.open(EditOfferModalComponent, { windowClass: "modal-edit-offer", size: 'lg' });
		modalRef.componentInstance.offer = offer;

		modalRef.result.then((result) => {
			if (result === true) {
				
			}
		});
	}
	disableOffer(offer: BundleOfferDto) {
		this.spinner.show();
		this.httpService.actionOffer(offer, 'disable')
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Disabling offer', error);
				this.toastService.showDanger({ content: 'Failed to disable offer: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.success) {
				this.toastService.showDanger({ content: 'Failed to disable offer: ' + data.error });
				return;
			}

			this.toastService.showSuccess({ content: 'Offer disabled successfully'});
			this.onActionSuccess.emit({});
		});
	}
	reEnableOffer(offer: BundleOfferDto) {
		this.spinner.show();
		this.httpService.actionOffer(offer, 'enable')
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Enabling offer', error);
				this.toastService.showDanger({ content: 'Failed to enable offer: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.success) {
				this.toastService.showDanger({ content: 'Failed to enable offer: ' + data.error });
				return;
			}

			this.toastService.showSuccess({ content: 'Offer enabled successfully'});
			this.onActionSuccess.emit({});
		});
	}

	goBack(): void {
		this.location.back()
	}
}