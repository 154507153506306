import { Component, Input, OnInit } from "@angular/core";
import { BundleOfferRangeDto } from "../../../dto";
import { faBan, faCancel, faCheck, faEdit, faIdCard, faMoneyBills, faSearch, faSimCard, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
import { UtilService } from "../../../util.service";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { AuthService } from "src/app/service/auth.service";
import { environment } from "src/environments/environment";
import { HttpAirtimeService } from "../../../http-airtime.service";

@Component({
	selector: 'app-range-table-data',
	templateUrl: './range-table-data.component.html',
	styleUrls: ['./range-table-data.component.scss']
})
export class RangeTableDataComponent implements OnInit {

	@Input() payload: BundleOfferRangeDto[];

	util = UtilService;

	constructor(
		private authService: AuthService,
		private modalService: NgbModal,
		private activatedRoute: ActivatedRoute,
		private toastService: AppToastService,
		private router: Router,) {}

	view = faSearch;
	disabled = faBan;
	unlock = faUnlock;
	edit = faEdit;

	ok=faCheck;
	notOk=faCancel;

	ngOnInit(): void {
		//If there is only one range, then simulate a click on it to go straight to the offers.
		//This is done so because with Telma, we have multiple ranges. However, with Econet we only have 1 static
		//range. The design is that if there's 1 range, the client doesn't show it. As it's 1, and nothing can be done
		//with it in the client, other than clicking on it. So the same should be true for the GUI, especially since the
		//range is never visible, and any config / customization is pointless. So just go straight to the linked offers.
		if (this.payload.length == 1) {
			this.viewOffers(this.payload[0]);
		}
	}

	getIcon(val: boolean) {
		return val === true ? this.ok : this.notOk;
	}
	getIconClass(val: boolean) {
		return val === true ? 'text-success' : 'text-danger';
	}
	viewOffers(range: BundleOfferRangeDto) {
		this.router.navigate(['/kiosk','view-offers', range.id]);
	}
	getButtonVisible(action: string): boolean {
		const allowedAction = environment.allowedActions.filter(item => item.action === action)[0];
		if (!allowedAction) {
			return false;
		}
		
		return this.authService.hasAnyRole(allowedAction.role);
	}
	editRange(range: BundleOfferRangeDto) {
		console.log('Edit range: ', range);
	}
	disableRange(range: BundleOfferRangeDto) {
		console.log('Disable range: ', range);
	}
	reEnableRange(range: BundleOfferRangeDto) {
		console.log('Enable range: ', range);
	}
	
}