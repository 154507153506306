<ng-container *ngIf="offer">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit Offer - {{offer.name}}</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title" (click)="cancel()">
		</button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-lg-6 mb-3 mx-auto input-group">
				<span class="input-group-text">Name</span>
				<input type="text" class="form-control" id="name" aria-describedby="name" name="name" [(ngModel)]="offer.name" [maxlength]="30">
			</div>
		</div>
		<div class="row" *ngFor="let header of headers">
			<div class="col-lg-6 mb-3 mx-auto input-group">
				<span class="input-group-text">{{header}}</span>
				<input type="text" class="form-control" [id]="header" [name]="header" [ngModel]="getModel(header).value" (ngModelChange)="updateExtra(getModel(header), $event)" [maxlength]="getModel(header).maxLength">
			</div>
		</div>
		<div class="row" *ngFor="let icon of offer.icons">
			<div class="col-lg-6 mb-3 mx-auto input-group">
				<div class="card m-3 shadow-lg bg-white rounded">
					<div class="card-header p-3 file-upload-card-header">
						Upload Offer Image ({{icon.language}})
					</div>
					<div class="card-body" file-dropzone (onFileDropped)="onFileSelected(icon, $event)">
						<div class="row"><div class="col text-sm text-center">This image will be displayed on the kiosk in the Bundle Offer list</div></div>
						<div class="row file-upload">
							<div class="col text-center">
								<div class="row">
									<div class="col text-center">Drag and drop your file here</div>
								</div>
								<div class="row">
									<div class="col text-center m-2">or</div>
								</div>
								<div class="row">
									<div class="col text-center">
										<input type="file" (change)="onFileUpload(icon, $event)" />
									</div>
								</div>
							</div>
						</div>
						<div class="row" *ngIf="icon.file">
							<div class="col table-responsive">
								<table class="table table-hover">
									<thead>
										<tr>
											<th>Type</th>
											<th>Size</th>
											<th>Thumbnail</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{{icon.file.type}}</td>
											<td>{{icon.fileSize}} bytes</td>
											<td><img *ngIf="icon.fileUrl" [src]="icon.fileUrl" width="96" height="84"/></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>		
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary" (click)="cancel()">Cancel</button>
		<button type="button" class="btn btn-success" (click)="save()">Save</button>
	</div>
</ng-container>