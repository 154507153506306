
<div class="row mt-2 vertical-on-small">
	<div class="col input-group input-group-sm">
		<span class="input-group-text">Filter</span>
		<input type="text" size="10" class="form-control" id="filter" aria-describedby="filter" name="filter" [(ngModel)]="filter" [maxlength]="30" (keydown)="filterChanged()">
		<div class="input-group-append input-group-sm" *ngIf="filter">
			<button type="button" class="btn bg-outline-secondary" (click)="clearFilter()">
				<fa-icon [icon]="clearSearch"></fa-icon>
			</button>
		</div>
		
	</div>
	<div class="col filter-spacer"></div>
	<div class="col input-group input-group-sm">
		<span class="input-group-text">Region</span>
		<select name="region" class="form-control" id="region" [(ngModel)]="region" (ngModelChange)="onRegionChange($event)">
			<option *ngFor="let region of regions" [ngValue]="region">{{region.region}}</option>
		</select>
		<div class="input-group-append input-group-sm" *ngIf="region">
			<button type="button" class="btn bg-outline-secondary" (click)="clearRegion()">
				<fa-icon [icon]="clearSearch"></fa-icon>
			</button>
		</div>
	</div>
	<div class="col input-group input-group-sm">
		<span class="input-group-text">Cities</span>
		<select name="city" class="form-control" id="city" [(ngModel)]="city" (ngModelChange)="onCityChange($event)">
			<option *ngFor="let city of cities" [ngValue]="city">{{city.city}}</option>
		</select>
		<div class="input-group-append input-group-sm" *ngIf="city">
			<button type="button" class="btn bg-outline-secondary" (click)="clearCity()">
				<fa-icon [icon]="clearSearch"></fa-icon>
			</button>
		</div>
	</div>
	
</div>

<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="kiosks" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Created At</th>
					<th>Verified At</th>
					<th>Last Seen At</th>
					<th>Device Ref</th>
					<th>Name</th>
					<th>Location</th>
					<th>Status</th>
					<th>Status Reason</th>
					<th>Authenticated</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let kiosk of visibleKiosks" (click)="viewKiosk(kiosk)">
					<th scope="row">{{kiosk.id}}</th>
					<td>{{kiosk.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{kiosk.verifiedAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{kiosk.lastSeenAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{kiosk.deviceRef}}</td>
					<td>{{kiosk.name}}</td>
					<td>{{util.getLocation(kiosk)}}</td>
					<td>
						<div [ngSwitch]="kiosk.kioskStatus">
							<fa-icon [icon]="verified" *ngSwitchCase="'Verified'" class="text-primary" title="Verified"></fa-icon>
							<fa-icon [icon]="new" *ngSwitchCase="'New'" class="text-success" title="New"></fa-icon>
							<fa-icon [icon]="blocked" *ngSwitchCase="'Blocked'" class="text-danger" title="Blocked"></fa-icon>
							<fa-icon [icon]="disabled" *ngSwitchCase="'Disabled'" class="text-danger" title="Disabled"></fa-icon>	
						</div>
					</td>
					<td>{{kiosk.statusReason}}</td>
					<td>
						<fa-icon [icon]="isAuthed" *ngIf="!isSecretInvalid(kiosk)" class="text-success"></fa-icon>
						<fa-icon [icon]="isNotAuthed" *ngIf="isSecretInvalid(kiosk)" class="text-danger"></fa-icon>	
					</td>
					<td>
						<div class="btn-group" role="group">
							<button class="btn btn-outline-secondary" 
								[routerLink]="['/kiosk','view', kiosk.id]" (click)="$event.stopPropagation()"
								type="button">
								View <fa-icon [icon]="view" size="lg"></fa-icon>
							</button>
							<button *ngIf="getButtonVisible('kiosk-edit')" class="btn btn-outline-secondary" 
								(click)="editKiosk(kiosk); $event.stopPropagation()" 
								type="button">
								Edit <fa-icon [icon]="edit" size="lg"></fa-icon>
							</button>
							<button *ngIf="kiosk.kioskStatus !== 'Disabled' && getButtonVisible('kiosk-edit')" 
								class="btn btn-outline-secondary" 
								(click)="disableKiosk(kiosk); $event.stopPropagation()" type="button">
								Disable <fa-icon [icon]="disabled" size="lg"></fa-icon>
							</button>
							<button *ngIf="canVerify(kiosk) && getButtonVisible('kiosk-verify')" 
								class="btn btn-outline-secondary" 
								(click)="verifyKiosk(kiosk); $event.stopPropagation()" 
								type="button">
								Verify <fa-icon [icon]="verified" size="lg"></fa-icon>
							</button>
							<button *ngIf="kiosk.kioskStatus === 'Blocked' && getButtonVisible('kiosk-edit')" 
								class="btn btn-outline-secondary" 
								(click)="unblockKiosk(kiosk); $event.stopPropagation()" 
								type="button">
								Unblock <fa-icon [icon]="unlock" size="lg"></fa-icon>
							</button>
							<button *ngIf="kiosk.kioskStatus === 'Disabled' && getButtonVisible('kiosk-edit')" 
								class="btn btn-outline-secondary" 
								(click)="reEnableKiosk(kiosk); $event.stopPropagation()" type="button">
								Re-enable <fa-icon [icon]="unlock" size="lg"></fa-icon>
							</button>
							<button *ngIf="!isSecretInvalid(kiosk) && getButtonVisible('kiosk-invalidate-auth')" 
								class="btn btn-outline-secondary" 
								(click)="invalidateSecret(kiosk); $event.stopPropagation()" 
								type="button">
								Invalidate Auth <fa-icon [icon]="disabled" size="lg"></fa-icon>
							</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
