import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from "@angular/core";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpKioskService } from "../../../../http-kiosk.service";
import { KioskBarcodeStatusDto, KioskCardDispenserStatusDto, KioskDto, KioskPrinterStatusDto, KioskStatusHighLevelEnum } from "../../../../dto";
import { ActivatedRoute } from "@angular/router";
import { catchError } from "rxjs/operators";
import { faHourglassStart, faLock, faMap, faArrowLeft, faBan, faCheck, faUnlock, faEdit, faTimes, faQuestion, faPrint, faSimCard, faCashRegister, faClipboard, faStopwatch, faCertificate, faCircle, faBarcode, faCoins, faMoneyBill, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import { Location } from "@angular/common";
import { UtilService } from "../../../../util.service";
import { KioskActionService } from "../../../../kiosk-action.service";
import { Subscription } from "rxjs";
import { Place } from "src/app/modules/kiosk/components/map/map.component";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/service/auth.service";

@Component({
	selector: 'app-kiosk-info',
	templateUrl: './kiosk-info.component.html',
	styleUrls: ['./kiosk-info.component.scss']
})
export class KioskInfoComponent implements OnDestroy {

	kiosk: KioskDto;
	@Output() reloadKiosk: EventEmitter<any> = new EventEmitter(); 
	back = faArrowLeft;

	new = faHourglassStart;
	blocked = faLock;
	verified = faCheck;
	disabled = faBan;
	unlock = faUnlock;
	edit = faEdit;
	sensorOk = faCircle;
	sensorNotOk = faCircle;
	statusOk = faCheck;
	statusNotOk = faTimes;
	statusUnknown = faQuestion;
	isNotAuthed = faUnlock;
	dispenser = faSimCard;
	printer = faPrint;
	barcode = faBarcode;
	cash = faCashRegister;
	details = faClipboard;
	kioskLocation=faMap;
	lastSeen=faStopwatch;
	status=faCertificate;
	coin=faCoins;
	note=faMoneyBill;
	coinValidator=faMoneyBillWave;

	kioskStatus = KioskStatusHighLevelEnum;

	util = UtilService;
	
	subscription = new Subscription();

	place: Place;

	env = environment;

	constructor(
		private activeRoute: ActivatedRoute, 
		private httpService: HttpKioskService,
		private toastService: AppToastService, 
		private authService: AuthService,
		private kioskActionService: KioskActionService) {
			this.subscription = this.kioskActionService.onActionSuccess.subscribe(item => {
				switch (item.action) {
					case "Edit":
					case "Verify":
					case "Invalidate":
					case "Disable":
					case "Unblock":
					case "ReEnable":
						this.reloadKiosk.emit();
						break;
				}
			});
	}

	@Input() 
	set kioskData(kiosk: KioskDto) {
		if (!kiosk) {
			return;
		}
		
		if (kiosk.location) {
			this.place = {
				name: kiosk.location.name,
				address: kiosk.location.address,
				city: kiosk.location.city,
				province: kiosk.location.region,
				latitude: +kiosk.location.latitude,
				longitude: +kiosk.location.longitude
				
			}
		}

		this.kiosk = kiosk;
	}

	verifyKiosk(kiosk: KioskDto) {
		this.kioskActionService.verifyKiosk(kiosk);
	}

	disableKiosk(kiosk: KioskDto) {
		this.kioskActionService.disableKiosk(kiosk);
	}

	unblockKiosk(kiosk: KioskDto) {
		this.kioskActionService.unblockKiosk(kiosk);
	}

	reEnableKiosk(kiosk: KioskDto) {
		this.kioskActionService.reEnableKiosk(kiosk);
	}

	invalidateSecret(kiosk: KioskDto) {
		this.kioskActionService.invalidateSecret(kiosk);
	}

	editKiosk(kiosk: KioskDto) {
		this.kioskActionService.editKiosk(kiosk);
	}

	isSecretInvalid(kiosk: KioskDto): boolean {
		return this.kioskActionService.isSecretInvalid(kiosk);
	}

	canVerify(kiosk: KioskDto): boolean {
		return this.kioskActionService.canVerify(kiosk);
	}

	get latestUpdate() {
		if (!this.kiosk.dispenser || !this.kiosk.printer) {
			return null;
		}
		const dates = [];
		dates.push(new Date(this.kiosk.dispenser.dispenserLastUpdated).getTime());
		dates.push(new Date(this.kiosk.dispenser.barcodeLastUpdated).getTime());
		dates.push(new Date(this.kiosk.printer.printerLastUpdated).getTime());
		if (this.kiosk.coinCashBox) {
			dates.push(new Date(this.kiosk.coinCashBox.createdAt).getTime());
		}
		if (this.kiosk.noteCashBox) {
			dates.push(new Date(this.kiosk.noteCashBox.createdAt).getTime());
		}
		return Math.max(...dates);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	getStatsCss(input: KioskCardDispenserStatusDto | KioskPrinterStatusDto | KioskBarcodeStatusDto) {
		switch (input.status.toString()) {
			case 'Severe':
				return 'text-danger';
			case 'Problem':
				return 'text-warning';
			case 'Ok':
				return 'text-success';
		}
	}

	get statusCss() {
		switch (this.kiosk.kioskStatus) {
			case 'New':
				return 'text-success';
			case 'Verified':
				return 'text-primary';
			case 'Blocked':
				return 'text-warning';
			case 'Disabled':
				return 'text-danger';
		}
	}

	getButtonVisible(action: string) {
		var allowed = this.authService.hasAnyRole(environment.allowedActions.filter(item => item.action === action)[0].role);
		if (!allowed) {
			return false;
		}
		if (action === 'kiosk-verify') {
			return this.kiosk.kioskStatus === 'New' || 
				(this.kiosk.secretInvalidAt ? new Date(this.kiosk.secretInvalidAt).getTime() < new Date().getTime() : true);
		}

		return true;
	}
}