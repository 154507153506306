import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivationStart, Router } from "@angular/router";
import { NgbOffcanvas, OffcanvasDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/service/auth.service";
import { faLayerGroup, faFileAlt, faTicket, faPeopleGroup, faPeopleArrows, faPowerOff, faPerson, faHouse, faWrench, faTools, faLocation, faLocationPin } from '@fortawesome/free-solid-svg-icons';
import { HttpConfigService } from "src/app/service/http-config.service";
import { HttpAdminService } from "../../http-admin.service";
import { KioskModule } from "../../kiosk.module";
import { Page, PageGroup } from "src/app/domain";
import { Observable, Subscriber, Subscription, timer } from "rxjs";
import { environment } from "src/environments/environment";

@Component({
	selector: 'app-top-bar',
	templateUrl: './top-bar.component.html',
	styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, OnDestroy {

	pageName: string;
	username: string;

	pageGroups: PageGroup[];

	closeResult = '';
	faLayerGroup = faLayerGroup;
	faFileAlt = faFileAlt;
	problem = faTicket;
	allAdmins=faPeopleGroup;
	addAdmin=faPeopleArrows;
	allTechs=faTools;
	addTech=faWrench;
	signOut=faPowerOff;
	profile=faPerson;	
	allLocations=faLocation;
	addLocation=faLocationPin;

	env = environment;

	subscription = new Subscription();

	constructor(
		public authService: AuthService,
		private configService: HttpConfigService,
		private adminService: HttpAdminService, 
		public router: Router,
		private offcanvasService: NgbOffcanvas) {
	}

	ngOnInit(): void {
		this.router.events.subscribe(data => {
			if (data instanceof ActivationStart) {
				if (data.snapshot.data.page) {
					this.pageName = data.snapshot.data.page.name;
				}
			}
		});

		this.username = this.authService.getUsername().toLowerCase();

		this.subscription = timer(1000, 2000).subscribe(x => this.loadPages());
	}

	private loadPages() {
		this.pageGroups = [];
		for (let pageGroup of KioskModule.getPages()) {
			var allowed = this.env.allowedActions.filter(item => item.action === pageGroup.action)[0];
			if (pageGroup.action === undefined || allowed) {
				var group = pageGroup.pages
					.filter(item => this.isEnabled(item.feature))
					.filter(item => this.authService.hasAnyRole(item.role))
					.filter(item => item.routerLink !== null);
				if (group.length > 0) {
					this.pageGroups.push({title: pageGroup.title, pages: group});
				}
			}
		}
	}

	open(content) {
		this.offcanvasService.open(content, { ariaLabelledBy: 'offcanvas-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === OffcanvasDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === OffcanvasDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on the backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	public isEnabled(feature: string): boolean {
		return this.configService.isFeatureEnabled(feature);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}