import { HttpParams, HttpEvent } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { KioskAppVersionStatusDto } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpAppVerionsService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/app/versions');
	}

	getKioskAppVersions(kioskId: number): Observable<KioskAppVersionStatusDto> {
		return super.get(`/${kioskId}/get`);
	}
}
