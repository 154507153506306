import { Component, Input } from "@angular/core";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { catchError } from "rxjs/operators";
import { faArrowLeft, faCheck, faCancel, faSimCard, faBarcode, faPrint, faCoins, faMoneyBill1Wave, faListCheck, faComputer, faCashRegister, faPowerOff, faBarsProgress } from '@fortawesome/free-solid-svg-icons';
import { HttpComponentsService } from "src/app/modules/kiosk/http-components.service";
import { NgxSpinnerService } from "ngx-spinner";
import { KioskAppVersionStatusDto, KioskComponentInitDto, KioskDto } from "../../dto";
import { HttpAppVerionsService } from "../../http-app-versions.service";

@Component({
	selector: 'app-kiosk-app-versions',
	templateUrl: './kiosk-app-versions.component.html',
	styleUrls: ['./kiosk-app-versions.component.scss']
})
export class KioskAppVersionsComponent {
	kiosk: KioskDto;
	appVersion: KioskAppVersionStatusDto;

	applicationVersions=faBarsProgress;

	readonly initStatusCount = 8;

	constructor(
		private httpService: HttpAppVerionsService,
		private toastService: AppToastService,
		private spinner: NgxSpinnerService,) {
	}

	@Input() 
	set kioskData(kiosk: KioskDto) {
		if (!kiosk) {
			return;
		}
		this.kiosk = kiosk;
		this.fetchKioskAppVersion();
	}

	private fetchKioskAppVersion() {
		this.spinner.show();
		this.httpService.getKioskAppVersions(this.kiosk.id)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching data', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(item => {
			this.spinner.hide();
			this.appVersion = item;
		});
	}
}