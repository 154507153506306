<div class="modal-header">
	<h4 class="modal-title" id="modal-title">{{tpName}} submission</h4>
	<button type="button" class="btn-close" aria-describedby="modal-title" (click)="close()">
	</button>
</div>
<div class="modal-body table-responsive">
	<table class="table table-borderless">
		<thead>
			<tr>
				<th>Third Party</th>
				<th>Start</th>
				<th>End</th>
				<th>Timing</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>{{tpName}}</td>
				<td>{{tpRequestDate ? (tpRequestDate | date:'yyyy-MM-dd HH:mm:ss') : 'N/A'}}</td>
				<td>{{tpResponseDate ? (tpResponseDate | date:'yyyy-MM-dd HH:mm:ss') : 'N/A'}}</td>
				<td>{{tpTiming ? (tpTiming / 1000) : 'N/A'}} (s)</td>
			</tr>
		</tbody>
	</table>
	<div class="card">
		<div class="card-header"><strong>URL</strong></div>
		<div class="card-body">{{tpUrl}}</div>
	</div>
	<div class="card">
		<div class="card-header"><strong>Headers</strong></div>
		<div class="card-body">{{tpHeaders}}</div>
	</div>
	<div class="card">
		<div class="card-header"><strong>Request</strong></div>
		<div class="card-body">{{tpRequest}}</div>
	</div>
	<div class="card">
		<div class="card-header"><strong>Response</strong></div>
		<div class="card-body">{{tpResponse}}</div>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
</div>