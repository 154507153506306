import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { KioskUserDto, TechnicianPayloadDto } from "../../dto";
import { faCheck, faPerson, faHourglassStart, faUnlock, faBan, faEdit, faSearch, faLock, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpTechnicianService } from "../../http-technician.service";
import { TechnicianActionService } from "../../kiosk-technician.service";

@Component({
	selector: 'app-technician-list',
	templateUrl: './technician-list.component.html',
	styleUrls: ['./technician-list.component.scss']
})
export class TechnicianListComponent implements OnInit, OnDestroy {

	technicians: TechnicianPayloadDto[];

	add = faPlusCircle;
	verified = faCheck;
	new = faHourglassStart;
	blocked = faLock;
	disabled = faBan;
	unlock = faUnlock;
	edit = faEdit;
	view = faSearch;
	editRoles = faPerson;

	subscription = new Subscription();

	constructor(
		private httpService: HttpTechnicianService, 
		private technicianActionService: TechnicianActionService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,) {
			this.subscription = this.technicianActionService.onActionSuccess.subscribe (item => {
				switch (item.action) {
					case "Edit":
					case "Disable":
					case "Unblock":
					case "ReEnable":
						this.fetchAll();
						break;
				}
			});
	}

	ngOnInit(): void {
		this.fetchAll();
	}

	private fetchAll() {
		this.spinner.show();
		this.httpService.getAll()
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching technicians', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(items => {
			this.technicians = items;
			this.spinner.hide();
		});
	}

	addTechnician() {
		this.router.navigate(['/kiosk','add-technician']);
	}

	editTechnician(technician: KioskUserDto) {
		this.technicianActionService.editTechnician(technician);
	}

	disableTechnician(technician: KioskUserDto) {
		this.technicianActionService.disableTechnician(technician);
	}

	unblockTechnician(technician: KioskUserDto) {
		this.technicianActionService.unblockTechnician(technician);
	}

	reEnableTechnician(technician: KioskUserDto) {
		this.technicianActionService.reEnableTechnician(technician);
	}

	viewTechnician(technician: KioskUserDto) {
		this.router.navigate(['/kiosk','view-technician', technician.id]);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}