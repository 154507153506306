<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="payload" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Created At</th>
					<th>Kiosk</th>
					<th>Type</th>
					<th>Name</th>
					<th>Surname</th>
					<th>Birthdate</th>
					<th>Gender</th>
					<th>ICCID</th>
					<th>ID Number</th>
					<th>Nationality</th>
					<th>Payment Type</th>
					<th *ngIf="env.displayMomoAccount">Momo Account</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let data of payload">
					<th scope="row">{{data.customer.id}}</th>
					<td>{{data.customer.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{getKioskDetails(data.customer.kiosk)}}</td>
					<td>{{data.customer.txType}}</td>
					<td>{{data.customer.name}}</td>
					<td>{{data.customer.surname}}</td>
					<td>{{data.customer.birthDate | date:'yyyy-MM-dd'}}</td>
					<td>{{data.customer.gender}}</td>
					<td>
						<button class="btn btn-outline-secondary" 
							(click)="viewSims(data.sims); $event.stopPropagation()" 
							type="button">
							{{data.sims[0]?.iccid}}&nbsp;&nbsp;<fa-icon [icon]="simCard" size="lg"></fa-icon>
						</button>
					</td>
					<td>
						<button class="btn btn-outline-secondary" 
							(click)="viewCustomerId(data.customer); $event.stopPropagation()" 
							type="button">
							{{data.customer.idNumber}}&nbsp;&nbsp;<fa-icon [icon]="idCard" size="lg"></fa-icon>
						</button>
					</td>
					<td>{{data.customer.nationality}}</td>
					<td>{{data.customer.paymentType}}</td>
					<td *ngIf="data.customer.momoAccount && env.displayMomoAccount">
						<button class="btn btn-outline-secondary" 
							(click)="viewMomoAccount(data.customer); $event.stopPropagation()" 
							type="button">
							{{data.customer.momoAccount}}&nbsp;&nbsp;<fa-icon [icon]="momo" size="lg"></fa-icon>
						</button>
					</td>
					<td *ngIf="!data.customer.momoAccount && env.displayMomoAccount">
						N/A
					</td>
					<td>
						<div class="btn-group" role="group">
							<button class="btn btn-outline-secondary" 
								[routerLink]="['/kiosk', 'customer-view', data.customer.id]" (click)="$event.stopPropagation()"
								type="button">
								View <fa-icon [icon]="view" size="lg"></fa-icon>
							</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
