import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit} from '@angular/core';
import { BundleOfferDto, BundleOfferExtraDto, BundleOfferIconDto, KioskDto, KioskLocationDto } from '../../../../dto';
import { HttpKioskService } from '../../../../http-kiosk.service';
import { AppToastService } from 'src/app/components/toasts/app-toast.service';
import { catchError } from 'rxjs/operators';
import { HttpLocationService } from '../../../../http-location.service';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpAirtimeService } from 'src/app/modules/kiosk/http-airtime.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-offer-edit-modal',
	templateUrl: './offer-edit-modal.component.html',
	styleUrls: ['./offer-edit-modal.component.scss']
})
export class EditOfferModalComponent {
	@Input() offer: BundleOfferDto;

	edits: BundleOfferExtraDto[] = [];

	constructor(
		public modal: NgbActiveModal, 
		private toastService: AppToastService, 
		private httpService: HttpAirtimeService,
		private spinner: NgxSpinnerService,
		private sanitizer: DomSanitizer) { }

	save() {
		//go through the edits and validate them
		var error = undefined;
		this.edits.forEach(extra => {
			if (extra.mandatory && extra.value === undefined) {
				error = extra.name + ' is required';
				return;
			}

			if ((extra.value as string).length > extra.maxLength) {
				error = extra.name + ' maximum length is ' + extra.maxLength;
				return;
			}

			switch (extra.type) {
				case 'Text':
					break;
				case 'Date':
					break;
				case 'Money':
					if ((extra.value as string).indexOf(".") > 1) {
						error = 'Please make sure ' + extra.name + ' is in valid format i.e 100.00';
						return;
					}
					break;
				case 'Number':
					if (isNaN(extra.value)) {
						error = 'Please make sure ' + extra.name + ' is a number';
						return;
					}
					break;
			}
		});

		if (error) {
			this.toastService.showSecondary({  content: error });
			return;
		}

		//Send edited offers to server:
		var offerCopy: BundleOfferDto = {
			id: this.offer.id,
			rangeId: this.offer.rangeId,
			createdAt: this.offer.createdAt,
			name: this.offer.name,
			active: this.offer.active,
			extras: this.edits,
			icons: this.offer.icons,
			validityEn: this.offer.validityEn,
			internetEn: this.offer.internetEn,
			smsEn: this.offer.smsEn,
			talkEn: this.offer.talkEn,
			validityFr: this.offer.validityFr,
			internetFr: this.offer.internetFr,
			smsFr: this.offer.smsFr,
			talkFr: this.offer.talkFr,
		}

		this.spinner.show();
		this.httpService.saveOfferDetails(offerCopy)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error editing offer', error);
				this.toastService.showDanger({ content: 'Failed to edit offer: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.success) {
				this.toastService.showDanger({ content: 'Failed to edit offer: ' + data.error });
				return;
			}

			//if all passes, then apply edits to offer
			this.offer.extras.forEach(extra => {
				var editedExtra = this.edits.filter(item => item.name === extra.name)[0];
				if (editedExtra) {
					extra.value = editedExtra.value;
				}
			});

			console.log('Extras edited:', this.offer.extras);

			this.toastService.showSuccess({ content: 'Offer saved successfully'});
			this.modal.close(true);
		});
	}

	get headers() {
		return this.offer.extras.map(extra =>{
			return extra.name;
		});
	}

	getModel(header: string) {
		return this.offer.extras.filter(extra => extra.name === header)[0];
	}

	updateExtra(extra: BundleOfferExtraDto, $event) {
		var extraEdit = this.edits.filter(item => item.name === extra.name)[0];
		if (extraEdit) {
			extraEdit.value = $event;
			return;
		}
		const copy: BundleOfferExtraDto =  {
			name: extra.name,
			description: extra.description,
			mandatory: extra.mandatory,
			maxLength: extra.maxLength,
			type: extra.type,
			value: extra.value
		};

		copy.value = $event;
		this.edits.push(copy);
	}

	getValue(header: string) {
		var extra = this.offer.extras.filter(extra => extra.name === header)[0];
		return extra.value;
	}

	onFileUpload(icon: BundleOfferIconDto, event: any) {
		this.onFileSelected(icon, [event.target.files[0]]);
	}

	onFileSelected(icon: BundleOfferIconDto, files: File[]) {
		if (files.length > 1) {
			this.toastService.showSecondary({ content: 'Please upload only 1 image' });
			return;
		}
		var file = files[0];
		if (file.type.toLowerCase() !== 'image/png') {
			this.toastService.showDanger({ content: 'Please upload file in png format' });
			return;
		}
		if (file.size > 100000) {
			this.toastService.showDanger({ content: 'Please upload a file with size less than 1 mb' });
			return;
		}
		this.resetFile(icon);

		icon.fileName = file.name;
		icon.fileFormat = file.type;
		icon.fileSize = file.size;

		icon.file = file;
		icon.fileUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(icon.file));
    }

	private resetFile(icon: BundleOfferIconDto) {
		icon.fileName = undefined;
		icon.fileFormat = undefined;
		icon.fileSize = undefined;
		icon.file = undefined;
		icon.fileUrl = undefined;
	}


	cancel() {
		this.modal.close(false);
	}
}