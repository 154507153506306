import { Component, OnDestroy, OnInit } from "@angular/core";
import { KioskRoleDto, KioskUserDto, MaintenancePermissionRoleDto } from "../../dto";
import { HttpAdminService } from "../../http-admin.service";
import { faArrowLeft,faArrowRight,faPeopleArrows, faWrench } from '@fortawesome/free-solid-svg-icons';
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalComponent } from "../../components/confirm-modal/confirm-modal.component";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpTechnicianService } from "../../http-technician.service";
import { UtilService } from "../../util.service";
import { Location } from "@angular/common";

@Component({
	selector: 'app-technician-add',
	templateUrl: './technician-add.component.html',
	styleUrls: ['./technician-add.component.scss']
})
export class TechnicianAddComponent implements OnInit{

	back=faArrowLeft;
	technician: KioskUserDto = {};
	newTech=faWrench;
	maintenanceRole: MaintenancePermissionRoleDto;
	maintenanceRoles: MaintenancePermissionRoleDto[];

	util = UtilService;

	constructor(
		private locationService: Location, 
		private httpService: HttpTechnicianService, 
		private toastService: AppToastService,
		private modalService: NgbModal,
		private spinner: NgxSpinnerService,) {
	}

	ngOnInit(): void {
		this.spinner.show();
		this.httpService.getMaintenanceRoles()
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching maintenance roles', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(items => {
			this.maintenanceRoles = items;
			console.log('Maintenance Roles:', this.maintenanceRoles);
			this.spinner.hide();			
		});
	}

	goBack(): void {
		this.locationService.back()
	}

	generateUsername() {
		if (this.technician.username) {
			return;
		}
		this.technician.username = this.util.generateUsername(this.technician);
	}

	addTechnician() {
		if (!this.technician.name) {
			this.toastService.showSecondary({ content: 'Please enter the name of the technician' });
			return;
		}

		if (!this.technician.surname) {
			this.toastService.showSecondary({ content: 'Please enter the surname of the technician' });
			return;
		}

		if (!this.technician.username) {
			this.toastService.showSecondary({ content: 'Please enter the username of the technician' });
			return;
		}

		if (!this.technician.msisdn) {
			this.toastService.showSecondary({ content: 'Please enter the MSISDN of the technician' });
			return;
		}

		if (!this.technician.email) {
			this.toastService.showSecondary({ content: 'Please enter the email of the technician' });
			return;
		}

		if (!this.maintenanceRole) {
			this.toastService.showSecondary({ content: 'Please select a maintenance role' });
			return;
		}

		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Add Technician';
		modalRef.componentInstance.text = "Please confirm you wish to add the technician.";
		modalRef.componentInstance.additionalText = "The technician will receive a notification with instructions to complete the setup of their account.";

		modalRef.result.then((result) => {
			if (result === true) {
				this.doAddTechnician();
			}
		});
	}
	private doAddTechnician() {
		this.spinner.show();
		this.httpService.addTechnician(this.technician, this.maintenanceRole)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error adding technician', error);
				this.toastService.showDanger({ content: 'Failed to add technician: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			console.log('data', data);
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to add technician: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Technician saved successfully'});

			this.technician = {};
			this.maintenanceRole = undefined;
		});
	}
}