<div class="row d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
	<div class="col">	
		<div class="btn-group" role="group">
			<button class="btn btn-outline-secondary" 
				(click)="addTechnician()" 
				type="button">
				Add <fa-icon [icon]="add" size="lg"></fa-icon>
			</button>
		</div>			
	</div>
</div>
<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="technicians" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Created At</th>
					<th>Username</th>
					<th>Status</th>
					<th>Name</th>
					<th>Surname</th>
					<th>MSISDN</th>
					<th>Email</th>
					<th>Maintenance Role</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let tech of technicians">
					<th scope="row">{{tech.id}}</th>
					<td>{{tech.technician.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{tech.technician.username}}</td>
					<td>
						<div [ngSwitch]="tech.technician.userStatus">
							<fa-icon [icon]="verified" *ngSwitchCase="'Verified'" class="text-primary" title="Verified"></fa-icon>
							<fa-icon [icon]="new" *ngSwitchCase="'New'" class="text-success" title="New"></fa-icon>
							<fa-icon [icon]="blocked" *ngSwitchCase="'Blocked'" class="text-danger" title="Blocked"></fa-icon>
							<fa-icon [icon]="disabled" *ngSwitchCase="'Disabled'" class="text-danger" title="Disabled"></fa-icon>	
						</div>
					</td>
					<td>{{tech.technician.name}}</td>
					<td>{{tech.technician.surname}}</td>
					<td>{{tech.technician.msisdn}}</td>
					<td>{{tech.technician.email}}</td>
					<td>{{tech.maintenanceRole?.role}}</td>
					<td>
						<div class="btn-group" role="group">
							<button class="btn btn-outline-secondary" 
								(click)="editTechnician(tech.technician); $event.stopPropagation()" 
								type="button">
								Edit <fa-icon [icon]="edit" size="lg"></fa-icon>
							</button>
							<button *ngIf="tech.userStatus !== 'Disabled'" 
								class="btn btn-outline-secondary" 
								(click)="disableTechnician(tech.technician); $event.stopPropagation()" type="button">
								Disable <fa-icon [icon]="disabled" size="lg"></fa-icon>
							</button>
							<button *ngIf="tech.userStatus === 'Blocked'" 
								class="btn btn-outline-secondary" 
								(click)="unblockTechnician(tech.technician); $event.stopPropagation()" 
								type="button">
								Unblock <fa-icon [icon]="unlock" size="lg"></fa-icon>
							</button>
							<button *ngIf="tech.userStatus === 'Disabled'" 
								class="btn btn-outline-secondary" 
								(click)="reEnableTechnician(tech.technician); $event.stopPropagation()" type="button">
								Re-enable <fa-icon [icon]="unlock" size="lg"></fa-icon>
							</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	
</div>
