<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="offerConfig" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Range</th>
					<th>Created At</th>
					<th>Name</th>
					<th>Active</th>
					<th *ngFor="let header of headers">{{header.title}}</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let offer of offerConfig">
					<th scope="row">{{offer.id}}</th>
					<td>{{range.name}}</td>
					<td>{{offer.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{offer.name}}</td>
					<td><fa-icon [icon]="getIcon(offer.active)" class="text-primary fs-4" [ngClass]="getIconClass(offer.active)"></fa-icon></td>
					<td *ngFor="let header of headers">
						<ng-container *ngIf="header.type === 'icon'" [ngTemplateOutlet]="icon" 
										[ngTemplateOutletContext]="{title:header.title, offer: offer}"></ng-container>
						<ng-container *ngIf="header.type === 'text'">{{getValue(header, offer)}}</ng-container>
					</td>	
					<td>
						<div class="btn-group" role="group">
							<button *ngIf="getButtonVisible('kiosk-airtime-edit-offer')" class="btn btn-outline-secondary" 
								(click)="editOffer(offer); $event.stopPropagation()" 
								type="button">
								Edit <fa-icon [icon]="edit" size="lg"></fa-icon>
							</button>
							<button *ngIf="offer.active && getButtonVisible('kiosk-airtime-edit-offer')" 
								class="btn btn-outline-secondary" 
								(click)="disableOffer(offer); $event.stopPropagation()" type="button">
								Disable <fa-icon [icon]="disabled" size="lg"></fa-icon>
							</button>
							<button *ngIf="!offer.active && getButtonVisible('kiosk-airtime-edit-offer')" 
								class="btn btn-outline-secondary" 
								(click)="reEnableOffer(offer); $event.stopPropagation()" type="button">
								Re-enable <fa-icon [icon]="unlock" size="lg"></fa-icon>
							</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<ng-template #icon let-title='title' let-offer='offer'>
    <img [src]="getBundleIcon(title, offer)" class="bundle-icon"/>
</ng-template>