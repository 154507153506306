
<div *ngIf="kiosk && appVersion" class="card m-3 shadow-lg bg-white rounded">
	<div class="card-header"><fa-icon [icon]="applicationVersions" class="text-secondary"></fa-icon>&nbsp;Application Version(s)</div>
	<div class="card-body table-responsive">
		<table class="table table-hover">
			<thead>
				<tr>
					<th>Updated At</th>
					<th>App</th>
					<th>Version</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let appVer of appVersion.list">
					<td>{{appVer.updatedAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{appVer.app}}</td>
					<td>{{appVer.version}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>