import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { AirtimeDto, BundleOfferAuditDto, BundleOfferDto, BundleOfferIconDto, BundleOfferRangeDto, KioskUserDto } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpAirtimeService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/airtime');
	}

	getConfig(): Observable<AirtimeDto> {
		return super.get(`/airtimeconfig`);
	}

	getAll(from: Date, to: Date, msisdn: string, kioskId: number, airtimeType: string, airtimeRangeId: number, airtimeOfferId: number, airtimeAmountStart: string, airtimeAmountEnd: string): Observable<AirtimeDto> {
		var search = {
			from: from, 
			to: to, 
			msisdn: msisdn, 
			kioskId: kioskId,
			airtimeType: airtimeType,
			airtimeRangeId: airtimeRangeId,
			airtimeOfferId: airtimeOfferId,
			airtimeAmountStart: airtimeAmountStart,
			airtimeAmountEnd: airtimeAmountEnd
		}
		return super.post(`/get-airtime`, search);
	}

	getBundleConfig(): Observable<BundleOfferRangeDto[]> {
		return super.get(`/bundleconfig`);
	}
	getBundleConfigByRangeId(rangeId: number): Observable<BundleOfferRangeDto> {
		return super.get(`/bundleconfig/${rangeId}`);
	}

	saveOfferDetails(offer: BundleOfferDto): Observable<AirtimeDto> {
		var formData = new FormData();
		offer.icons.forEach(icon => {
			formData.append("bundleImage" + icon.language.toString(), icon.file);
			//Remove any file objects from icon to clean submission
			icon.fileName = undefined;
			icon.fileFormat = undefined;
			icon.fileSize = undefined;
			icon.file = undefined;
			icon.fileUrl = undefined;

			formData.append("icon" + icon.language.toString(), JSON.stringify(icon));
		});
		
		formData.append("payload", JSON.stringify(offer));
		return super.post(`/save-offer-details`, formData);
	}
	actionOffer(offer: BundleOfferDto, action: 'enable' | 'disable'): Observable<AirtimeDto> {
		return super.get(`/action/${action}/${offer.id}`);
	}
	getAllAudits(from: Date, to: Date, userId: number): Observable<BundleOfferAuditDto[]> {
		var search = {
			from: from, 
			to: to, 
			userId: userId
		}
		return super.post(`/get-audits`, search);
	}
	getBundleIcon(iconId: number, language: string): Observable<Blob> {
		return super.doDownloadFileSimple(`/${iconId}/${language}/bundle-icon`);
	}
	getBundleIconAudit(iconAuditId: number, language: string, type: string): Observable<Blob> {
		return super.doDownloadFileSimple(`/${iconAuditId}/${language}/${type}/bundle-icon-audit`);
	}
	
}
