import { SafeUrl } from '@angular/platform-browser';
import { AbstractDto } from 'src/app/service/dto';

export enum CashTypeEnum {
	Coin,
	Note
}

export enum CashRouteEnum {
	Recycle, // Payout
	Store // Cashbox
}

export enum PeripheralEnum {
	CardDispenser,
	Printer,
	Cashbox,
	Barcode
}

export class KioskCashBoxLevelDto extends AbstractDto {
	id: number;
	createdAt: Date;
	cashValue: number; //denomination
	country: string;
	cashLevel: number; //quantity
	cashType: CashTypeEnum;
	cashRoute: CashRouteEnum;
}

export class KioskCashBoxDto extends AbstractDto{
	id: number;
	createdAt: Date;
	kiosk: KioskDto;
	isLatest: Boolean;
	totalCnt: number;
	totalValue: number;
	levels: KioskCashBoxLevelDto[];
}

export enum CardBoxStatusEnum {
	Full,
	Unknown,
	Low,
	Empty
}

export enum CardErrorTypeEnum {
	None,
    Jam,
    Overlap
}

export enum CardProcessErrorTypeEnum {
	None,
	Capture,
	Dispense
}

export enum CardStuckPositionEnum {
	None,
	Capture,
	Dispense
}

export enum KioskStatusHighLevelEnum {
	Ok,
	Problem,
	Severe
}
export class KioskBarcodeStatusDto {
	status: KioskStatusHighLevelEnum;
	lastUpdated: Date;
}

export class KioskCardDispenserStatusDto extends AbstractDto {
	kiosk: KioskDto;
	dispenserLastUpdated: Date;
	dispenserSerial: string;
	sensor1: boolean;
	sensor2: boolean;
	sensor3: boolean;
	cardBinFull: boolean;
	cardBoxStatus: CardBoxStatusEnum;
	cardError: CardErrorTypeEnum;
	processError: CardProcessErrorTypeEnum;
	stuckPosition: CardStuckPositionEnum;
	status: KioskStatusHighLevelEnum;
	barcodeLastUpdated: Date;
	barcodeStatus: KioskBarcodeStatusDto;	
}

export enum PrinterStatusEnum {
	FullPaper,
	LowPaper,
	NoMorePaper
}

export class KioskPrinterStatusDto extends AbstractDto {
	kiosk: KioskDto;
	printerLastUpdated: Date;
	printerSerial: string;
	printerStatus: PrinterStatusEnum;
	status: KioskStatusHighLevelEnum;
}

export class KioskCoinValidatorStatusDto extends AbstractDto {
	coinValidatorLastUpdated: Date;
	coinValidatorSerial: string;
	coinValidatorStatus: boolean;
	status: KioskStatusHighLevelEnum;
}

export class KioskCoinValidatorDto extends AbstractDto {
	id: number;
	createdAt: Date;
	costInCents: number;
    scaling: number;
    decimalValue: number;
    countryCode: string;
    cost: string;
}

export class KioskHopperCoinStatusDto extends AbstractDto {
	hopperCoinLastUpdated: Date;
	hopperCoinDatasetVersion: string;
	hopperCoinSerial: string;
	hopperCoinStatus: boolean;
	status: KioskStatusHighLevelEnum;
}

export class KioskHopperNoteStatusDto extends AbstractDto {
	hopperNoteLastUpdated: Date;
	hopperNoteDatasetVersion: string;
	hopperNoteSerial: string;
	hopperNoteStatus: boolean;
	status: KioskStatusHighLevelEnum;
}

export class KioskDto extends AbstractDto {
	id?: number;
	createdAt?: Date;
	verifiedAt?: Date;
	lastSeenAt?: Date;
	secretInvalidAt?: Date;
	deviceRef?: string;
	kioskStatus?: 'New' | 'Verified' | 'Blocked' | 'Disabled' = 'New';
	statusReason?: string;
	name?: string;
	location?: KioskLocationDto;
	dispenser?: KioskCardDispenserStatusDto;
	printer?: KioskPrinterStatusDto;
	coinCashBox?: KioskCashBoxDto;
	noteCashBox?: KioskCashBoxDto;
	coinValidatorStatus?: KioskCoinValidatorStatusDto;
	coinValidator?: KioskCoinValidatorDto[];
	hopperCoinStatus?: KioskHopperCoinStatusDto;
	hopperNoteStatus?: KioskHopperNoteStatusDto;
	visible?: boolean = true;
	linkedToVideo?: boolean;
}

export class KioskUserDto extends AbstractDto {
	id?: number;
	createdAt?: Date;
	username?: string;
	password?: string;
	userStatus?: 'New' | 'Verified' | 'Blocked' | 'Disabled' = 'New';
	name?: string;
	surname?: string;
	email?: string;
	msisdn?: string;
	roles?: KioskRoleDto[];
}

export class KioskRoleDto extends AbstractDto {
	id: number;
	createdAt: Date;
	roleName: string;
}

export class KioskUserRoleDto extends AbstractDto {
	id: number;
	createdAt: Date;
	adminUserId: number;
	adminRoleId: number;
}

export class UserChangePasswordDto extends AbstractDto {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}

export class KioskLocationDto extends AbstractDto {
	id?: number;
	createdAt?: Date;
	name?: string;
	address?: string;
	city?: string;
	region?: string;
	latitude?: string;
	longitude?: string;
	active?: number;
}

export class KioskComponentDto extends AbstractDto {
	id: number;
	kiosk: KioskDto;
	createdAt: Date;
	updatedAt: Date;
	type: 'SerialPort' | 'SerialDevice' | 'UsbDevice' | 'UsbToSerialDevice';
	component: string;
}

export class KioskComponentInitDto extends AbstractDto {
	id: number;
	kiosk: KioskDto;
	createdAt: Date;
	isLatest: boolean;
	dispenser: boolean;
	barcode: boolean;
	printer: boolean;
	coinValidator: boolean;
	hopperCoins: boolean;
	hopperNotes: boolean;
	kioskStatus: boolean;
	powerStatus: boolean;
}

export class KioskSettingDto extends AbstractDto {
	id: number;
	createdAt: Date;
	updatedAt: Date;
	name: string;
	value: string;
	description: string;
	inputType?: string;
}

export class KioskMonitorSettingDto extends AbstractDto {
	id: number;
	monitor: string;
	severeThreshold: number;
	severeThresholdDesc: string;
	problemThreshold: number;
	problemThresholdDesc: string;
	severeRestore: number;
	severeRestoreDesc: string;
	problemRestore: number;
	problemRestoreDesc: string;
	escalation: number;
	escalationDesc: string;
	error?: string;
}

export class KioskMonitorBasicDto extends AbstractDto {
	createdAt: Date; 
	monitor: string;
	enabled: boolean;
}

export class KioskMonitorGroupedDto extends AbstractDto {
	kiosk: KioskDto;
	monitors: KioskMonitorBasicDto[];
}

export class KioskMonitorStatusDto extends AbstractDto {
	id: number;
	createdAt: Date;
	updatedAt: Date;
	escalatedAt: Date;
	kiosk: KioskDto;
	monitor: string;
	status: string;
	alert: string;
	peripheral?: PeripheralEnum;
	logs?: KioskMaintenanceLogDto[];
	showCurrentLogsOnly?: boolean = true;
}

export class KioskMaintenanceLogDto extends AbstractDto {
	id: number;
	createdAt: Date;
	kiosk: KioskDto;
	username: string;
	peripheral: PeripheralEnum;
	log: string;
}

export class AdminKioskResponseDto extends AbstractDto {

}

export class KioskVideoDto extends AbstractDto {
	id?: number;
	createdAt?: Date;
	enabledAt?: Date;
	disabledAt?: Date;
	title?: string;
	description?: string;
	videoName?: string;
	videoSize?: number;
	videoFormat?: string;
	videoDuration?: number;
	targetAge?: string;
	targetGender?: string;
	targetRace?: string;
	fileLocation?: string;
	thumbnailLocation?: string;
	thumbnailName?: string;
	thumbnail?: any;
}

export class KioskVideoLinkDto extends AbstractDto {
	id?: number;
	createdAt?: Date;
	user?: KioskUserDto;
	kiosk?: KioskDto;
	video?: KioskVideoDto;
}

export enum TransactionTypeEnum {
	New,
	SimSwap,
	ResetCode,
	Claim,
	MomoAccount
}

export enum IdTypeEnum {
	Cni,
	Passport,
	IdCard,
	DriversLicence,
	ConsularCard
}

export enum GenderEnum {
	Male,
	Female
}

export enum ImageTypeEnum {
	Portrait,
	Document,
	DocumentPortrait,
	DocumentGhostPortrait,
	DocumentSignature,
	DocumentBarcode,
	Signature,
}
export enum FingerprintTypeEnum {
	Lt,
	Li,
	Lm,
	Lr,
	Lp,
	Rt,
	Ri,
	Rm,
	Rr,
	Rp
}

export enum PaymentTypeEnum {
	CreditCard,
	Cash
}

export class CustomerDto extends AbstractDto {
	id: number;
	createdAt: Date;
	kiosk: KioskDto;
	txType: TransactionTypeEnum;
	name: string;
	surname: string;
	birthDate: Date;
	gender: GenderEnum;
	idNumber: string;
	documentNumber: string;
	idType: IdTypeEnum;
	idIssuedAt: Date;
	idExpiryAt: Date;
	idIssuePlace: string;
	nationality: string;
	paymentType: PaymentTypeEnum;
	momoAccount: number;
	momoBalance: number;
	momoLastTransfer: number;
	momoLastRecharge: number;
	momoLastThreeDialed: string;
}

export class CustomerSimDto {
	id: number;
	customer: CustomerDto;
	createdAt: Date;
	msisdn: string;
	imsi: string;
	iccid: string;
	puk1: string;
	puk2: string;
}

export class CustomerImageDto {
	customer: CustomerDto;
	createdAt: Date;
	imageType: ImageTypeEnum;
	images: ImageDataDto[];
	imageIndex: number;
}

export class ImageDataDto {
	id: number;
	image: any;
	imageSize: number;
}

export class CustomerFingerprintDto {
	id: number;
	customer: CustomerDto;
	createdAt: Date;
	type: FingerprintTypeEnum;
	jpeg: any;
	wsq: any;
	template: any;
}

export class CustomerPayloadDto {
	customer: CustomerDto;
	sims: CustomerSimDto[];
	images: CustomerImageDto[];
	prints: CustomerFingerprintDto[];
}

export class FailedTxDto {
	id: number;
	createdAt: Date;
	kiosk: KioskDto;
	txId: String;
	txType: TransactionTypeEnum;
	data: String;
}

export class AirtimeDto {
	success: boolean;
	error: string;
	//List of airtime types i.e. Borne has Bundle, Credit Ticket and Top up amount. 
	//Econet has Bundle and Top up Amount. The respective operator must provide their own unique types in this list
	airtimeType: string[];
	airtimeRange: AirtimeRangeDto[];
	data: AirtimeDataDto[];
}

export class AirtimeRangeDto {
	id: number;
	range: string;
	airtimeType: string;
	offers: AirtimeOfferDto[];
}
export class AirtimeOfferDto {
	id: number;
	offer: string;
}
export class AirtimeDataDto {
	id: number;
	createdAt: Date;	
	kiosk: KioskDto;
	txId: string;
	range: string;
	offer: string;
	debitMsisdn: string; // the msisdn that paid for the airtime
	beneficiaryMsisdn: string; // the msisdn that received the airtime
	paymentType: string;
	currency: string;
	amount: string;
	success: boolean;
	tpName: string;
	tpUrl: string;
	tpHeaders: string;
	tpRequest: string;
	tpResponse: string;
	tpRequestDate: Date;
	tpResponseDate: Date;
	tpTiming: number;
	error: string;
}

export class BundleOfferRangeDto {
	id: number;
	createdAt: Date;
	name: string;
	active: boolean;
	icon_path: string;
	icon_menu_path: string;
	offers: BundleOfferDto[];
}
export class BundleOfferDto {
	id: number;
	rangeId: number;
	createdAt: Date;
	name: string;
	active: boolean;
	extras: BundleOfferExtraDto[];
	icons?: BundleOfferIconDto[];
	validity?: string;
	internet?: string;
	sms?: string;
	talk?: string;
	supportedLanguages?: BundleIconLanguageEnum[];
}

export class BundleOfferExtraDto {
	name: string;
	value: any;
	type: 'Text' | 'Number' | 'Money' | 'Date';
	maxLength: number;
	description: string;
	mandatory: boolean;
}

export enum BundleIconLanguageEnum {
	En,
	Fr
}
export class BundleOfferIconDto {
	id: number;
	offerId: number;
	language: BundleIconLanguageEnum;
	//for UI purposes only
	fileName?: string;
	fileFormat?: string;
	fileSize?: number;
	file?: File;
	fileUrl?: SafeUrl;
}

export class BundleOfferAuditDto {
	id: number;
	offer: BundleOfferDto;
	user: KioskUserDto;
	createdAt: Date;
	previousName: string;
	currentName: string;
	previousActive: boolean;
	currentActive: boolean;
	previousCostUsd: string;
	currentCostUsd: string;
	previousCostZig: string;
	currentCostZig: string;
	previousCode: string;
	currentCode: string;
	iconAudits?: BundleOfferIconsAuditDto[];
}

export class BundleOfferIconsAuditDto {
	id: number;
	audit: BundleOfferAuditDto;
	createdAt: Date;
	language: BundleIconLanguageEnum;
}