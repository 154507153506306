import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { KioskUserDto, MaintenancePermissionConfigDto, MaintenancePermissionDto, MaintenancePermissionRoleDto, MaintenancePermissionsEnum } from "../../dto";
import { faCheck, faPerson, faHourglassStart, faUnlock, faBan, faEdit, faSearch, faLock, faPlusCircle, faCross, faTimes, faQuestionCircle, faArrowRotateRight, faCalendar, faArrowRotateForward, faArrowTurnUp, faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpTechnicianService } from "../../http-technician.service";
import { TechnicianActionService } from "../../kiosk-technician.service";

class RolePermissionGroup {
		role: MaintenancePermissionRoleDto;
		group: CategoryGroup[];
}
class CategoryGroup {
	category: string;
	config: MaintenancePermissionConfigDto[];
}

@Component({
	selector: 'app-technician-maintenance-role',
	templateUrl: './technician-maintenance-role.component.html',
	styleUrls: ['./technician-maintenance-role.component.scss']
})
export class TechnicianMaintenanceRoleComponent implements OnInit, OnDestroy {

	@Input() specificRole: string;

	roles: MaintenancePermissionRoleDto[];

	add = faPlusCircle;
	hasPerm = faCheck;
	noPerm = faTimes;
	edit = faEdit;
	tooltip = faQuestionCircle;
	switch = faArrowRightArrowLeft;
	date=faCalendar;

	rolePerms: RolePermissionGroup[] = [];

	subscription = new Subscription();

	constructor(
		private httpService: HttpTechnicianService, 
		private technicianActionService: TechnicianActionService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,) {
			this.subscription = this.technicianActionService.onActionSuccess.subscribe (item => {
				switch (item.action) {
					case "Edit":
					case "Disable":
					case "Unblock":
					case "ReEnable":
						this.fetchAll();
						break;
				}
			});
	}

	ngOnInit(): void {
		this.fetchAll();
	}

	private fetchAll() {
		this.spinner.show();
		var obs = undefined;
		if (this.specificRole) {
			obs = this.httpService.getMaintenancePermissionByRole(this.specificRole);
		} else {
			obs = this.httpService.getAllMaintenancePermissionRoles()
		}
		
		obs.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching maintenance permissions', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(items => {
			if (this.specificRole) {
				this.roles = [];
				this.roles.push(items);
			} else {
				this.roles = items;
			}			
			console.log('Maintenance Permission Roles:', this.roles);
			this.spinner.hide();
			this.groupCategories();
			
		});
	}

	private groupCategories() {
		this.roles.forEach(role => {
			var currentCategory = undefined;
			var rolePerm: RolePermissionGroup = {
				role: role,
				group: []
			};
			role.permissions.forEach(item => {
				
				var categoryGroup: CategoryGroup = undefined;
				if (currentCategory !== item.category) {
					categoryGroup = {
						category: item.category,
						config: []
					}
					categoryGroup.config.push(item);
					rolePerm.group.push(categoryGroup);
				} else {
					categoryGroup = rolePerm.group.filter(item => item.category === currentCategory)[0];
					categoryGroup.config.push(item);
				}
				currentCategory = item.category;
				this.rolePerms.push(rolePerm);
			});
		});
		console.log('Organised Role Perms:', this.rolePerms);
	}

	getRolePerm(id: number): RolePermissionGroup {
		return this.rolePerms.filter(item => item.role.id === id)[0];
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}