
<div class="row mt-2 vertical-on-small">
	<div class="col input-group input-group-sm">
		<span class="input-group-text">User</span>
		<select name="user" class="form-control" id="user" [(ngModel)]="userId">
			<option *ngFor="let user of users" [ngValue]="user.id">{{user.name}} {{user.surname}} ({{user.username}})</option>
		</select>
		<div class="input-group-append input-group-sm" *ngIf="userId">
			<button type="button" class="btn bg-outline-secondary" (click)="userId = undefined">
				<fa-icon [icon]="clearSearch"></fa-icon>
			</button>
		</div>
	</div>
	<div class="col input-group input-group-sm">
		<span class="input-group-text">From</span>
		<input
			class="form-control"
			placeholder="yyyy-mm-dd"
			name="dp"
			[(ngModel)]="fromModel"
			ngbDatepicker
			#d="ngbDatepicker"
		/>
		<button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><fa-icon [icon]="date" class="float-end text-secondary"></fa-icon></button>
		<div class="input-group-append input-group-sm" *ngIf="fromModel">
			<button type="button" class="btn bg-outline-secondary" (click)="fromModel = undefined">
				<fa-icon [icon]="clearSearch" class="text-secondary"></fa-icon>
			</button>
		</div>
	</div>
	<div class="col input-group input-group-sm">
		<span class="input-group-text">To</span>
		<input
			class="form-control"
			placeholder="yyyy-mm-dd"
			name="dp"
			[(ngModel)]="toModel"
			ngbDatepicker
			#toDate="ngbDatepicker"
		/>
		<button class="btn btn-outline-secondary" (click)="toDate.toggle()" type="button"><fa-icon [icon]="date" class="float-end text-secondary"></fa-icon></button>
		<div class="input-group-append input-group-sm" *ngIf="toModel">
			<button type="button" class="btn bg-outline-secondary" (click)="toModel = undefined">
				<fa-icon [icon]="clearSearch" class="text-secondary"></fa-icon>
			</button>
		</div>
	</div>
	<div class="col text-end">
		<button type="button" class="btn btn-outline-secondary" (click)="doSearch()">
			<fa-icon [icon]="search"></fa-icon>&nbsp;Search
		</button>
	</div>
	
</div>

<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="audits" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Offer</th>
					<th>User</th>
					<th>Created At</th>
					<th>Previous Name</th>
					<th>Current Name</th>
					<th>Previous Active</th>
					<th>Current Active</th>
					<th>Previous Cost USD</th>
					<th>Current Cost USD</th>
					<th>Previous Cost ZIG</th>
					<th>Current Cost ZIG</th>
					<th>Previous Code</th>
					<th>Current Code</th>
					<th *ngFor="let header of iconHeaders">{{header}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let audit of audits">
					<th scope="row">{{audit.id}}</th>
					<td>{{audit.offer.name}}</td>
					<td>{{audit.user.name}} {{audit.user.surname}} ({{audit.user.username}})</td>
					<td>{{audit.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{audit.previousName}}</td>
					<td>{{audit.currentName}}</td>
					<td><fa-icon [icon]="getIcon(audit.previousActive)" class="text-primary fs-4" [ngClass]="getIconClass(audit.previousActive)"></fa-icon></td>
					<td><fa-icon [icon]="getIcon(audit.currentActive)" class="text-primary fs-4" [ngClass]="getIconClass(audit.currentActive)"></fa-icon></td>
					<td>{{audit.previousCostUsd}}</td>
					<td>{{audit.currentCostUsd}}</td>
					<td>{{audit.previousCostZig}}</td>
					<td>{{audit.currentCostZig}}</td>
					<td>{{audit.previousCode}}</td>
					<td>{{audit.currentCode}}</td>
					<td *ngFor="let header of iconHeaders">
						<ng-container [ngTemplateOutlet]="icon" 
										[ngTemplateOutletContext]="{title:header, audit: audit}"></ng-container>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<ng-template #icon let-title='title' let-audit='audit'>
    <img [src]="getIconValues(audit, title)" class="bundle-icon"/>
</ng-template>
