<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="roles" class="table">
			<thead>
				<tr>
					<th>Role</th>
					<th>Description</th>
					<th>Config</th>
				</tr>
			</thead>
			<tbody *ngIf="rolePerms">
				<tr *ngFor="let role of roles">
					<td>{{role.role}}</td>
					<td>{{role.description}}</td>
					<td>
						<div class="row">
							<div class="col-lg-auto d-flex align-items-stretch" *ngFor="let group of getRolePerm(role.id).group">
								<div class="card mb-1">
									<div class="card-header">{{group.category}}</div>
									<div class="card-body pt-0 pb-0">
										<div class="table-responsive" style="overflow: visible;">
											<table class="table table-sm table-hover">
												<thead>
													<tr>
														<th>Permission</th>
														<th>Description</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let config of group.config">
														<td>{{config.permission}}</td>
														<td>{{config.description}}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
