<ng-container *ngIf="kiosk">
	<div class="row d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center m-2 mb-0 pb-0">
		<div class="col">
			{{util.getKioskDetails(kiosk)}}
		</div>
		<div class="col text-end">	
			<div class="btn-group bg-white" role="group">
				<button *ngIf="getButtonVisible('kiosk-edit')" class="btn btn-outline-secondary" 
					(click)="editKiosk(kiosk)" 
					type="button">
					Edit <fa-icon [icon]="edit" size="lg"></fa-icon>
				</button>
				<button 
					*ngIf="getButtonVisible('kiosk-verify')"
					class="btn btn-outline-secondary" 
					(click)="verifyKiosk(kiosk)" 
					type="button"
					[disabled]="!canVerify(kiosk)">
					Verify <fa-icon [icon]="verified" size="lg"></fa-icon>
				</button>
				<button 
					*ngIf="kiosk.kioskStatus === 'Blocked' && getButtonVisible('kiosk-edit')"
					class="btn btn-outline-secondary" 
					(click)="unblockKiosk(kiosk)" 
					type="button">
					Unblock <fa-icon [icon]="unlock" size="lg"></fa-icon>
				</button>
				<button 
					*ngIf="kiosk.kioskStatus === 'Disabled' && getButtonVisible('kiosk-edit')" 
					class="btn btn-outline-secondary" 
					(click)="reEnableKiosk(kiosk)" 
					type="button">
					Re-enable <fa-icon [icon]="unlock" size="lg"></fa-icon>
				</button>
				<button 
					*ngIf="kiosk.kioskStatus !== 'Disabled' && getButtonVisible('kiosk-edit')" 
					class="btn btn-outline-secondary" 
					(click)="disableKiosk(kiosk)" 
					[disabled]="kiosk.kioskStatus === 'Blocked'"
					type="button">
					Disable <fa-icon [icon]="disabled" size="lg"></fa-icon>
				</button>
				<button *ngIf="getButtonVisible('kiosk-invalidate-auth')" class="btn btn-outline-secondary" 
					(click)="invalidateSecret(kiosk)" 
					[disabled]="isSecretInvalid(kiosk)"
					type="button">
					Invalidate Auth <fa-icon [icon]="disabled" size="lg"></fa-icon>
				</button>
			</div>			
		</div>
	</div>
	<div class="row" *ngIf="kiosk.printer && kiosk.dispenser">
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded stats">
				<div class="card-body">
					<table class="table table-borderless text-center">
						<thead>
							<tr>
								<th><fa-icon [icon]="dispenser" class="text-primary fs-4"></fa-icon><br/>Dispenser</th>
								<th><fa-icon [icon]="barcode" class="text-primary fs-4"></fa-icon><br/>Barcode</th>
								<th><fa-icon [icon]="printer" class="text-primary fs-4"></fa-icon><br/>Printer</th>
								<th><fa-icon [icon]="cash" class="text-primary fs-4"></fa-icon><br/>Cash</th>
								<th><fa-icon [icon]="status" class="text-primary fs-4"></fa-icon><br/>Status</th>
								<th><fa-icon [icon]="lastSeen" class="text-primary fs-4"></fa-icon><br/>Last Seen</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td [class]="getStatsCss(kiosk.dispenser)">{{kiosk.dispenser?.status}}</td>
								<td [class]="getStatsCss(kiosk.dispenser.barcodeStatus)">{{kiosk.dispenser?.barcodeStatus?.status}}</td>
								<td [class]="getStatsCss(kiosk.printer)">{{kiosk.printer?.status}}</td>
								<td>{{kiosk.coinCashBox?.totalValue + kiosk.noteCashBox?.totalValue}}</td>
								<td [class]="statusCss">{{kiosk.kioskStatus}}</td>
								<td>{{latestUpdate !== null ? (latestUpdate | timeago:true) : ''}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<app-kiosk-component-init [kioskData]="kiosk"></app-kiosk-component-init>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="row">
				<div class="col">
					<div class="card m-3 shadow-lg bg-white rounded">
						<div class="card-header p-3">
							<fa-icon [icon]="details" class="text-secondary"></fa-icon>&nbsp;Details
						</div>
						<div class="card-body table-responsive">
							<table class="table table-hover">
								<thead>
									<tr>
										<th>ID</th>
										<th>Created At</th>
										<th>Verified At</th>
										<th>Last Seen At</th>
										<th>Device Ref</th>
										<th>Name</th>
										<th>Location</th>
										<th>Status</th>
										<th *ngIf="kiosk.secretInvalidAt">Authentication</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{{kiosk.id}}</td>
										<td>{{kiosk.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
										<td>{{kiosk.verifiedAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
										<td>{{kiosk.lastSeenAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
										<td>{{kiosk.deviceRef}}</td>
										<td>{{kiosk.name}}</td>
										<td>{{util.getLocation(kiosk)}}</td>
										<td>
											<div [ngSwitch]="kiosk.kioskStatus">
												<fa-icon [icon]="verified" *ngSwitchCase="'Verified'" class="text-primary" title="Verified"></fa-icon>
												<fa-icon [icon]="new" *ngSwitchCase="'New'" class="text-success" title="New"></fa-icon>
												<fa-icon [icon]="blocked" *ngSwitchCase="'Blocked'" class="text-danger" title="Blocked"></fa-icon>
												<fa-icon [icon]="disabled" *ngSwitchCase="'Disabled'" class="text-danger" title="Disabled"></fa-icon>	
												{{kiosk.kioskStatus}}
											</div>
										</td>
										<td *ngIf="kiosk.secretInvalidAt">
											<fa-icon [icon]="isNotAuthed" class="text-danger"></fa-icon> Requires re-authentication
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<div class="card m-3 shadow-lg bg-white rounded">
						<div class="card-header p-3">
							<fa-icon [icon]="printer" class="text-secondary"></fa-icon>&nbsp;Printer
						</div>
						<div class="card-body table-responsive">
							<small *ngIf="!kiosk.printer">Printer information unavailable</small>
							<table *ngIf="kiosk.printer" class="table table-hover">
								<thead>
									<tr>
										<th>Status</th>
										<th>Last Update</th>
										<th>Serial</th>
										<th>Printer Status</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td [ngClass]="{'text-danger':kiosk.printer.status.toString() === 'Severe'}">
											{{kiosk.printer.status}}
										</td>
										<td>{{kiosk.printer.printerLastUpdated | timeago:true}}</td>
										<td>{{kiosk.printer.printerSerial}}</td>
										<td>{{kiosk.printer.printerStatus}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>	
			</div>
		</div>	
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header p-3">
					<fa-icon [icon]="kioskLocation" class="text-secondary"></fa-icon>&nbsp;Location
				</div>
				<div class="card-body table-responsive">
					<small *ngIf="!kiosk.location">No location set</small>
					<div *ngIf="kiosk.location" class="row">
						<div class="col mb-3 mx-auto">
							<app-map 
								[location]="place"
								[maxHeight]="250"
								[editable]="false"></app-map>
						</div>
					</div>
				</div>
			</div>
		</div>	
	</div>
	<div class="row">
		<div class="col" *ngIf="env.displayAppVersions">
			<app-kiosk-app-versions [kioskData]="kiosk"></app-kiosk-app-versions>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header p-3">
					<fa-icon [icon]="dispenser" class="text-secondary"></fa-icon>&nbsp;Dispenser
				</div>
				<div class="card-body table-responsive">
					<small *ngIf="!kiosk.dispenser">Dispenser information unavailable</small>
					<table *ngIf="kiosk.dispenser" class="table table-hover">
						<thead>
							<tr>
								<th>Status</th>
								<th>Last Update</th>
								<th>Serial</th>
								<th>Sensor 1</th>
								<th>Sensor 2</th>
								<th>Sensor 3</th>
								<th>Card Bin Full</th>
								<th>Card Box Status</th>
								<th>Card Error</th>
								<th>Process Error</th>
								<th>Stuck Position</th>
								<th>Barcode Update</th>
								<th>Barcode Status</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td [ngClass]="{'text-danger':kiosk.dispenser.status.toString() === 'Severe'}">
									{{kiosk.dispenser.status}}
								</td>
								<td>{{kiosk.dispenser.dispenserLastUpdated | timeago:true}}</td>
								<td>{{kiosk.dispenser.dispenserSerial}}</td>
								<td><ng-container [ngTemplateOutlet]="sensorStatus" 
										[ngTemplateOutletContext]="{sensorStatus:kiosk.dispenser.sensor1}"></ng-container></td>
								<td><ng-container [ngTemplateOutlet]="sensorStatus" 
										[ngTemplateOutletContext]="{sensorStatus:kiosk.dispenser.sensor2}"></ng-container></td>
								<td><ng-container [ngTemplateOutlet]="sensorStatus" 
									[ngTemplateOutletContext]="{sensorStatus:kiosk.dispenser.sensor3}"></ng-container></td>
								<td><ng-container [ngTemplateOutlet]="standardStatus" 
									[ngTemplateOutletContext]="{standardStatus:kiosk.dispenser.cardBinFull}"></ng-container></td>
								<td>{{kiosk.dispenser.cardBoxStatus}}</td>
								<td>{{kiosk.dispenser.cardError}}</td>
								<td>{{kiosk.dispenser.processError}}</td>
								<td>{{kiosk.dispenser.stuckPosition}}</td>
								<td>{{kiosk.dispenser.barcodeLastUpdated == null ? '' : kiosk.dispenser.barcodeLastUpdated | timeago:true}}</td>
								<td>
									<ng-container [ngTemplateOutlet]="barcodeStatus" 
									[ngTemplateOutletContext]="{barcodeStatus:kiosk.dispenser.barcodeStatus}"></ng-container></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>	
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header p-3">
					<fa-icon [icon]="cash" class="text-secondary"></fa-icon>&nbsp;Coin Validator / Acceptor
				</div>
				<div class="card-body table-responsive">
					<small *ngIf="!kiosk.coinValidatorStatus">Coin Validator / Acceptor information unavailable</small>
					<table *ngIf="kiosk.coinValidatorStatus" class="table table-hover">
						<thead>
							<tr>
								<th>Status</th>
								<th>Last Update</th>
								<th>Serial</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td [ngClass]="{'text-danger':kiosk.coinValidatorStatus.status.toString() === 'Severe'}">
									{{kiosk.coinValidatorStatus.status}}
								</td>
								<td>{{kiosk.coinValidatorStatus.coinValidatorLastUpdated | timeago:true}}</td>
								<td>{{kiosk.coinValidatorStatus.coinValidatorSerial}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header p-3">
					<fa-icon [icon]="coin" class="text-secondary"></fa-icon>&nbsp;Hopper Coin
				</div>
				<div class="card-body table-responsive">
					<small *ngIf="!kiosk.hopperCoinStatus">Hopper Coin information unavailable</small>
					<table *ngIf="kiosk.hopperCoinStatus" class="table table-hover">
						<thead>
							<tr>
								<th>Status</th>
								<th>Last Update</th>
								<th>Serial</th>
								<th>Dataset Version</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td [ngClass]="{'text-danger':kiosk.hopperCoinStatus.status.toString() === 'Severe'}">
									{{kiosk.hopperCoinStatus.status}}
								</td>
								<td>{{kiosk.hopperCoinStatus.hopperCoinLastUpdated | timeago:true}}</td>
								<td>{{kiosk.hopperCoinStatus.hopperCoinSerial}}</td>
								<td>{{kiosk.hopperCoinStatus.hopperCoinDatasetVersion}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>	
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header p-3">
					<fa-icon [icon]="note" class="text-secondary"></fa-icon>&nbsp;Hopper Note
				</div>
				<div class="card-body table-responsive">
					<small *ngIf="!kiosk.hopperNoteStatus">Hopper Note information unavailable</small>
					<table *ngIf="kiosk.hopperNoteStatus" class="table table-hover">
						<thead>
							<tr>
								<th>Status</th>
								<th>Last Update</th>
								<th>Serial</th>
								<th>Dataset Version</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td [ngClass]="{'text-danger':kiosk.hopperNoteStatus.status.toString() === 'Severe'}">
									{{kiosk.hopperNoteStatus.status}}
								</td>
								<td>{{kiosk.hopperNoteStatus.hopperNoteLastUpdated | timeago:true}}</td>
								<td>{{kiosk.hopperNoteStatus.hopperNoteSerial}}</td>
								<td>{{kiosk.hopperNoteStatus.hopperNoteDatasetVersion}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>	
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header p-3">
					<fa-icon [icon]="coin" class="text-secondary"></fa-icon>&nbsp;Coin Summary
				</div>
				<div class="card-body table-responsive">
					<small *ngIf="!kiosk.coinCashBox">Coin information unavailable</small>
					<table *ngIf="kiosk.coinCashBox" class="table table-hover">
						<thead>
							<tr>
								<th>Last Updated</th>
								<th>Total Coin(s)</th>
								<th>Total Coin Value</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{{kiosk.coinCashBox.createdAt | timeago:true}}</td>
								<td>{{kiosk.coinCashBox.totalCnt}}</td>
								<td>{{kiosk.coinCashBox.totalValue}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header p-3">
					<fa-icon [icon]="note" class="text-secondary"></fa-icon>&nbsp;Note Summary
				</div>
				<div class="card-body table-responsive">
					<small *ngIf="!kiosk.noteCashBox">Note information unavailable</small>
					<table *ngIf="kiosk.noteCashBox" class="table table-hover">
						<thead>
							<tr>
								<th>Last Updated</th>
								<th>Total Note(s)</th>
								<th>Total Note Value</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{{kiosk.noteCashBox.createdAt | timeago:true}}</td>
								<td>{{kiosk.noteCashBox.totalCnt}}</td>
								<td>{{kiosk.noteCashBox.totalValue}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>	
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header p-3">
					<fa-icon [icon]="coin" class="text-secondary"></fa-icon>&nbsp;Coin Details
				</div>
				<div class="card-body table-responsive">
					<small *ngIf="!kiosk.coinCashBox?.levels">Coin level information unavailable</small>
					<table *ngIf="kiosk.coinCashBox?.levels" class="table table-hover">
						<thead>
							<tr>
								<th>Denomination</th>
								<th>Country</th>
								<th>Quantity</th>
								<th>Type</th>
								<th>Route</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let level of kiosk.coinCashBox.levels">
								<td>{{level.cashValue}}</td>
								<td>{{level.country}}</td>
								<td>{{level.cashLevel}}</td>
								<td>{{level.cashType}}</td>
								<td>{{level.cashRoute}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header p-3">
					<fa-icon [icon]="note" class="text-secondary"></fa-icon>&nbsp;Note Details
				</div>
				<div class="card-body table-responsive">
					<small *ngIf="!kiosk.noteCashBox?.levels">Note level information unavailable</small>
					<table *ngIf="kiosk.noteCashBox?.levels" class="table table-hover">
						<thead>
							<tr>
								<th>Denomination</th>
								<th>Country</th>
								<th>Quantity</th>
								<th>Type</th>
								<th>Route</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let level of kiosk.noteCashBox.levels">
								<td>{{level.cashValue}}</td>
								<td>{{level.country}}</td>
								<td>{{level.cashLevel}}</td>
								<td>{{level.cashType}}</td>
								<td>{{level.cashRoute}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>	
	</div>
</ng-container>

<ng-template #sensorStatus let-sensorStatus='sensorStatus'>
    <fa-icon *ngIf="sensorStatus === true" [icon]="sensorOk"  class="text-success"></fa-icon>
	<fa-icon *ngIf="sensorStatus === false" [icon]="sensorNotOk" class="text-danger"></fa-icon>
</ng-template>
<ng-template #standardStatus let-standardStatus='standardStatus'>
    <fa-icon *ngIf="standardStatus === true" [icon]="statusOk"  class="text-success"></fa-icon>
	<fa-icon *ngIf="standardStatus === false" [icon]="statusNotOk" class="text-danger"></fa-icon>
</ng-template>
<ng-template #barcodeStatus let-barcodeStatus='barcodeStatus'>
	<fa-icon *ngIf="barcodeStatus.alive == null" [icon]="statusUnknown"  class="text-secondary"></fa-icon>
    <fa-icon *ngIf="barcodeStatus?.alive === true" [icon]="statusOk"  class="text-success"></fa-icon>
	<fa-icon *ngIf="barcodeStatus?.alive === false" [icon]="statusNotOk" class="text-danger"></fa-icon>
</ng-template>
