import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { BundleIconLanguageEnum, BundleOfferAuditDto, BundleOfferIconsAuditDto, KioskDto, KioskLocationDto, KioskUserDto } from "../../../dto";
import { HttpKioskService } from "../../../http-kiosk.service";
import { faCheck, faHourglassStart, faLock, faUnlock, faBan, faEdit, faSearch, faTimes, faCalendar, faCancel } from '@fortawesome/free-solid-svg-icons';
import { NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UtilService } from "../../../util.service";
import { KioskActionService } from "../../../kiosk-action.service";
import { Subject, Subscription } from "rxjs";
import { HttpLocationService } from "../../../http-location.service";
import { catchError, debounceTime } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/service/auth.service";
import { environment } from "src/environments/environment";
import { HttpAirtimeService } from "../../../http-airtime.service";
import { HttpUserService } from "../../../http-user.service";
import { HttpAdminService } from "../../../http-admin.service";
import { UrlUtil } from "src/app/utils/url-util";

class Icon {
	title: string;
	id: number;
	icon?: any;
	type: string;
}

@Component({
	selector: 'app-offer-audit',
	templateUrl: './offer-audit.component.html',
	styleUrls: ['./offer-audit.component.scss']
})
export class OfferAuditListComponent implements OnInit {

	audits: BundleOfferAuditDto[];
	date=faCalendar;
	search=faSearch;
	clearSearch=faTimes;
	util = UtilService;

	private modelChanged: Subject<string> = new Subject<string>();
  	private modelSubscription: Subscription;
  	debounceTime = 500;
	filter: string;
	region: KioskLocationDto;
	city: KioskLocationDto;

	users: KioskUserDto[];
	userId: number;
	searchDate: Date;
	fromModel: NgbDateStruct;
	toModel: NgbDateStruct;

	icons: Icon[] = [];

	constructor(
		private httpService: HttpAirtimeService, 
		private router: Router,
		private adminService: HttpAdminService,
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,
		private activatedRoute: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.userId = UrlUtil.getParamAsNumber(this.activatedRoute, 'user');
		this.fromModel = UrlUtil.getParamAsNgbDate(this.activatedRoute, 'from');
		this.toModel = UrlUtil.getParamAsNgbDate(this.activatedRoute, 'to');	
		this.fetchAllAudits();
	}

	private fetchAllAudits() {
		var from: Date;
		if (this.fromModel !== undefined) {
			from = new Date();
			from.setFullYear(this.fromModel.year);
			from.setMonth(this.fromModel.month - 1);
			from.setDate(this.fromModel.day);
		}

		var to: Date;
		if (this.toModel !== undefined) {
			to = new Date();
			to.setFullYear(this.toModel.year);
			to.setMonth(this.toModel.month - 1);
			to.setDate(this.toModel.day);
		}
		this.userId = this.users?.filter(item => item.id === this.userId)[0]?.id;
		UrlUtil.setParams(this.router, {from: from, to: to, user: this.userId});

		this.spinner.show();
		this.httpService.getAllAudits(from, to, this.userId)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching audits', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(items => {
			this.audits = items;
			this.spinner.hide();

			this.audits.forEach(audit => {
				if (audit.iconAudits !== null) {
					audit.iconAudits.forEach(iconAudit => {
						this.httpService.getBundleIconAudit(iconAudit.id, iconAudit.language.toString().toLowerCase(), 'Previous')
						.subscribe(data => {
							this.createImageFromBlob(data, iconAudit, 'Previous');
						});	
						this.httpService.getBundleIconAudit(iconAudit.id, iconAudit.language.toString().toLowerCase(), 'Current')
						.subscribe(data => {
							this.createImageFromBlob(data, iconAudit, 'Current');
						});	
						
					});
				}
			});
		});

		if (!this.users) {
			this.adminService.getAll().subscribe(items => this.users = items);
		}
	}

	createImageFromBlob(image: Blob, auditIcon: BundleOfferIconsAuditDto, type: string) {
		var reader = new FileReader();
		reader.addEventListener("load", () => {
			var img = reader.result;
			console.log('Image for Audit Icon ID [' + auditIcon.id + '] and type [' + type + ']: ', img);
			var icon: Icon = {
				icon: reader.result,
				id: auditIcon.id,
				title: auditIcon.language.toString(), 
				type: type
			}
			this.icons.push(icon);
			
		}, false);
	 
		if (image) {
		   reader.readAsDataURL(image);
		}
	}

	ok=faCheck;
	notOk=faCancel;
	getIcon(val: boolean) {
		return val === true ? this.ok : this.notOk;
	}
	getIconClass(val: boolean) {
		return val === true ? 'text-success' : 'text-danger';
	}

	doSearch() {
		if (this.fromModel !== undefined && this.toModel === undefined) {
			this.toastService.showSecondary({ content: 'Please provide to date' });
			return;
		}
		if (this.toModel !== undefined && this.fromModel === undefined) {
			this.toastService.showSecondary({ content: 'Please provide from date' });
			return;
		}
		if (this.fromModel === undefined && this.toModel === undefined && this.userId === undefined) {
			this.toastService.showSecondary({ content: 'Please capture at least one search field' });
			return;
		}
		
		this.fetchAllAudits();
	}

	get iconHeaders(): string[] {
		var headers: string[] = [];

		var iconTitles: Set<String> = new Set();
		this.audits.forEach(audit => {
			if (audit.iconAudits !== null) {
				audit.iconAudits.forEach(iconAudit => {
					iconTitles.add(iconAudit.language.toString());
				});
			}
		});

		iconTitles.forEach(title => {
			headers.push('Previous ' + title);		
			headers.push('Current ' + title);
		});

		return headers;
	}

	getIconValues(audit: BundleOfferAuditDto, header: string): any {
		if (audit.iconAudits == null) {
			return;
		}

		if (header.includes('Previous')) {
			var auditIcon = audit.iconAudits.filter(item => item.audit.id === audit.id)[0];
			var out = this.icons
				.filter(icon => icon.id === auditIcon.id)
				.filter(icon => icon.type.includes('Previous'))[0]?.icon;
			return out;
		}
		if (header.includes('Current')) {
			var auditIcon = audit.iconAudits.filter(item => item.audit.id === audit.id)[0];
			var out = this.icons
				.filter(icon => icon.id === auditIcon.id)
				.filter(icon => icon.type.includes('Current'))[0]?.icon;
			return out;
		}
	}
}