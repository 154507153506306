<div class="row mt-2 vertical-on-small">
	<div class="col input-group input-group-sm">
		<span class="input-group-text">ICCID</span>
		<input type="text" size="10" class="form-control" id="iccid" aria-describedby="iccid" name="iccid" [(ngModel)]="iccidModel" [maxlength]="30">
		<div class="input-group-append input-group-sm" *ngIf="iccidModel">
			<button type="button" class="btn bg-outline-secondary" (click)="iccidModel = undefined">
				<fa-icon [icon]="clearSearch" class="text-secondary"></fa-icon>
			</button>
		</div>
	</div>
	<div class="col input-group input-group-sm">
		<span class="input-group-text">Kiosk</span>
		<select name="kiosk" class="form-control" id="kiosk" [(ngModel)]="kioskModel">
			<option *ngFor="let kiosk of kiosks" [ngValue]="kiosk">{{getKioskDetails(kiosk)}}</option>
		</select>
		<div class="input-group-append input-group-sm" *ngIf="kioskModel">
			<button type="button" class="btn bg-outline-secondary" (click)="kioskModel = undefined">
				<fa-icon [icon]="clearSearch" class="text-secondary"></fa-icon>
			</button>
		</div>
	</div>
	<div class="col input-group input-group-sm">
		<span class="input-group-text">From</span>
		<input
			class="form-control"
			placeholder="yyyy-mm-dd"
			name="dp"
			[(ngModel)]="fromModel"
			ngbDatepicker
			#d="ngbDatepicker"
		/>
		<button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><fa-icon [icon]="date" class="float-end text-secondary"></fa-icon></button>
		<div class="input-group-append input-group-sm" *ngIf="fromModel">
			<button type="button" class="btn bg-outline-secondary" (click)="fromModel = undefined">
				<fa-icon [icon]="clearSearch" class="text-secondary"></fa-icon>
			</button>
		</div>
	</div>
	<div class="col input-group input-group-sm">
		<span class="input-group-text">To</span>
		<input
			class="form-control"
			placeholder="yyyy-mm-dd"
			name="dp"
			[(ngModel)]="toModel"
			ngbDatepicker
			#toDate="ngbDatepicker"
		/>
		<button class="btn btn-outline-secondary" (click)="toDate.toggle()" type="button"><fa-icon [icon]="date" class="float-end text-secondary"></fa-icon></button>
		<div class="input-group-append input-group-sm" *ngIf="toModel">
			<button type="button" class="btn bg-outline-secondary" (click)="toModel = undefined">
				<fa-icon [icon]="clearSearch" class="text-secondary"></fa-icon>
			</button>
		</div>
	</div>
	<div class="col text-end">
		<button type="button" class="btn btn-outline-secondary" (click)="doSearch()">
			<fa-icon [icon]="search"></fa-icon>&nbsp;Search
		</button>
	</div>
</div>
<app-customer-table-data [payload]="payload"></app-customer-table-data>