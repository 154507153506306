import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit} from '@angular/core';
import { KioskDto, KioskLocationDto } from '../../../dto';
import { HttpKioskService } from '../../../http-kiosk.service';
import { AppToastService } from 'src/app/components/toasts/app-toast.service';
import { catchError } from 'rxjs/operators';
import { HttpLocationService } from '../../../http-location.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';

@Component({
	selector: 'app-edit-kiosk-modal',
	templateUrl: './edit-kiosk-modal.component.html',
	styleUrls: ['./edit-kiosk-modal.component.scss']
})
export class EditKioskModalComponent implements OnInit {
	@Input() kiosk: KioskDto;

	edit: KioskDto;
	locations: KioskLocationDto[];

	constructor(
		private router: Router,
		public modal: NgbActiveModal, 
		private toastService: AppToastService, 
		private httpService: HttpKioskService,
		private httpLocationService: HttpLocationService,
		private spinner: NgxSpinnerService) { }


	ngOnInit(): void {
		this.spinner.show();
		this.httpLocationService.getActiveLocations()
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching active locations', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(item => {
			this.spinner.hide();
			this.locations = item;

			this.edit = {
				name: this.kiosk.name,
				deviceRef: this.kiosk.deviceRef,
				location: this.kiosk.location ? this.locations.filter(item => item.id === this.kiosk.location.id)[0] : null
			}
		});
	}

	save() {

		if (!this.edit.name) {
			this.toastService.showSecondary({ content: 'Please enter the name of the kiosk' });
			return;
		}

		if (!this.edit.location) {
			this.toastService.showSecondary({ content: 'Please enter the location of the kiosk' });
			return;
		}

		this.spinner.show();
		this.httpService.editKiosk(this.edit)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error editing kiosk', error);
				this.toastService.showDanger({ content: 'Failed to edit kiosk: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to edit kiosk: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Kiosk saved successfully'});
			this.modal.close(true);
		});
	}

	cancel() {
		this.modal.close(false);
	}

	addLocation() {
		this.router.navigate(['/kiosk','add-location']);
		this.modal.close(true);
	}
}