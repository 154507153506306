<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="payload" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Created At</th>
					<th>Name</th>
					<th>Active</th>
					<th>Icon Path</th>
					<th>Icon Menu Path</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let data of payload" (click)="viewOffers(data)">
					<th scope="row">{{data.id}}</th>
					<td>{{data.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{data.name}}</td>
					<td><fa-icon [icon]="getIcon(data.active)" class="text-primary fs-4" [ngClass]="getIconClass(data.active)"></fa-icon></td>
					<td>{{data.iconPath}}</td>
					<td>{{data.iconMenuPath}}</td>
					<td>
						<div class="btn-group" role="group">
							<button class="btn btn-outline-secondary" 
								[routerLink]="['/kiosk','view-offers', data.id]" (click)="$event.stopPropagation()"
								type="button">
								View <fa-icon [icon]="view" size="lg"></fa-icon>
							</button>
							<button *ngIf="getButtonVisible('kiosk-airtime-edit-range')" class="btn btn-outline-secondary" 
								(click)="editRange(data); $event.stopPropagation()" 
								type="button">
								Edit <fa-icon [icon]="edit" size="lg"></fa-icon>
							</button>
							<button *ngIf="data.active && getButtonVisible('kiosk-airtime-edit-range')" 
								class="btn btn-outline-secondary" 
								(click)="disableRange(data); $event.stopPropagation()" type="button">
								Disable <fa-icon [icon]="disabled" size="lg"></fa-icon>
							</button>
							<button *ngIf="!data.active && getButtonVisible('kiosk-airtime-edit-range')" 
								class="btn btn-outline-secondary" 
								(click)="reEnableRange(data); $event.stopPropagation()" type="button">
								Re-enable <fa-icon [icon]="unlock" size="lg"></fa-icon>
							</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
