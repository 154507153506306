import { Component, Input, OnInit } from "@angular/core";
import { HttpAirtimeService } from "../../../http-airtime.service";
import { HttpKioskService } from "../../../http-kiosk.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { catchError } from "rxjs/operators";
import { BundleOfferDto, BundleOfferRangeDto } from "../../../dto";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Location } from "@angular/common";

@Component({
	selector: 'app-offer-config',
	templateUrl: './offer-config-list.component.html',
	styleUrls: ['./offer-config-list.component.scss']
})
export class OfferListComponent implements OnInit {

	rangeId: number;
	offers: BundleOfferDto[];
	range: BundleOfferRangeDto;

	back = faArrowLeft;

	constructor(
		private location: Location,
		private httpService: HttpAirtimeService,
		private httpKioskService: HttpKioskService,
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,
		private router: Router,
		private modalService: NgbModal,
		private activatedRoute: ActivatedRoute) {}

	ngOnInit(): void {
		this.fetchData();
	}

	fetchData() {
		this.activatedRoute.paramMap.subscribe(map => this.rangeId = +map.get('rangeId'));

		this.spinner.show();
		this.httpService.getBundleConfigByRangeId(this.rangeId)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching bundle config', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(resp => {
			this.range = resp;
			this.offers = this.range.offers;
			this.spinner.hide();
		});
	}

	goBack(): void {
		this.location.back()
	}

}